.header-carousel-container {
  .carousel-wrapper {
    position: relative;
    z-index: 1;
    margin-top: 58px;

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .carousel-indicators {
      li {
        border-radius: 0;
        margin: 0 3px;

        &.active {
          width: 10px;
          height: 10px;
        }
      }
    }

    .container {
      height: 100%;
      position: relative;
      z-index: 9;
    }

    .banner-gradient {
      @extend .gradient-diagonal;
      width: 100%;
      height: 100%;
      margin-top: -58px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    .carousel-inner {

      .item {
        height: 400px;
        background-size: cover;

        .carousel-caption {
          text-align: left;
          left: auto;
          right: auto;
          position: static;
          height: 100%;
          display: table;
          width: 100%;
          padding: 60px 0 0 0;

          .caption-content {
            display: table-cell;
            vertical-align: middle;

            h3 {
              @include font-size(24px);
              color: white;
              border: none;
              margin: 0 0 12px 0;
              padding-bottom: 0;
              line-height: 1.7rem;
            }

            a {
              text-decoration: none;

              span {
                @include font-size(14px);
                color: white;
                margin-bottom: 10px;
              }

              &.btn {
                margin-top: 12px;
                text-align: left;
                white-space: initial;

                span {
                  float: left;
                  margin-bottom: 0;

                  .lnr {
                    font-size: 20px !important;
                    float: right;
                    margin: -4px 0 0 24px;
                    font-weight: 300;
                  }
                }
              }

              &:hover {
                h3, span, .btn {
                  color: #eee;
                }
              }
            }
          }
        }
      }
    }
  }
}
