.cookie-bar {
  padding: 7px 0;
  background: $blue;
  color: $text-white;
  @extend .font-OpenSans;
  display: none;

  .lnr {
    //@include font-size(14px);
    font-size: 20px;
    color: $text-white;
    cursor: pointer;
    vertical-align: -webkit-baseline-middle;
  }

  a {
    &.more {
      margin-left: 5px;
    }
    color: $darkblue;
  }

  .cookie-text {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    line-height: 21px;

    p {
      margin: 0;
    }

    &* {
      display: inline-block;
      margin: 0;
    }
  }
}

.cookie-btn {
  color: #fff;
  background-color: $blue;
  border-color: #fff;
  border: 1px solid;
  border-radius: 4px;
  height: 30px;
  width: $cookie-btn-width;
}

.cookie-btn:hover{
  border-color: #a6c7ff;
}

.cookie-opt-in-out{
  cursor: pointer;
  color: $link-color;
  text-decoration: none;
}

.cookie-opt-in-out:hover {
  text-decoration: $link-hover-decoration;
  color: $link-hover-color;
}

@media screen and (max-width: 990px) {
  .cookie-btn {
    width: 80px;
  }
}

@media screen and (max-width: 750px) {
  .cookie-btn {
    width: $cookie-btn-width;
  }

  .cookie-bar {
    .pull-right {
      float: none !important;
      display: block;
      text-align: center;
      margin-top: 10px;
    }
  }
}