.mediaDownload,
.documentLibrary {
	.main .content .tab-content {
		margin-bottom: 70px;
	
		.folder {
			.row {
				margin-bottom: 20px;
				
				.asset {
					padding: 15px;
					height: 100%;
					background: $text-white;
					transition: background 0.2s;
					display: inline-block;
					float: none;
					
					&:hover {
						background: $lightestgray;
					}

					img {
						width: 100%;
						max-width: 100%;
						float: left;
					}
					
					.caption {
						float: left;
						margin-top: 5px;
						overflow-wrap: break-word;
  						word-wrap: break-word;
  						width: 100%;
  						color: $text-black;
					}
				}	
			}
			
			.form-query {
				padding-top: 20px;
				
				.download-zip,
				.download-doc-zip {
					float: right;
					margin-right: 15px;
				}
			}
		}
	}
	
	@media screen and (max-width: $screen-xs-max) {
		.pagination,
		.showing-results {
			display: flex;
		    justify-content: center;
		    align-items: center;
		}
		
		.form-query {
			> .form-group {
				display: flex;
			    justify-content: center;
			    align-items: center;
			    padding-right: 0;
			    margin-right: 0;
		    
		        .btn {
		        	margin: 0 5px 0 5px;
	        	}
        	}
        	
			.download-zip {
				display: flex;
			    justify-content: center;
			    align-items: center;
			    padding-right: 0;
			    margin-right: 0;
				float: none !important;
				margin-right: 0 !important;
			}
		}
		
		.pagination > li {
			> a {
				padding: 5px 10px;
				font-size: 12px;
				line-height: 1.5;
			}
		}
		
		.caption {
			text-align: center;
		}
	}
}