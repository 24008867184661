.video-header-wrapper {
  height: 470px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0;
  z-index: 3;

  .container {
    position: relative;
    height: auto;
    height: 470px;
  }

  img {
    @extend %image-header-background;
    display: none;
    z-index: 2;
  }

  .banner-gradient {
    @extend %banner-gradient-bg;
  }

  .video-header-bg {
    background: transparent;
    position: static;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -99;
  }

  .video-header-fg,
  .video-header-bg iframe,
  .video-header-bg video {
    position: absolute;
    pointer-events: none;
    height: 145%;
    top: -22%;
    width: 150%;
    left: -25%;
  }

  .video-header-headline {
    position: absolute;
    top: 200px;
    padding-right: 38px;
    z-index: 4;
    width: 100%;

    * {
      color: $text-white;
      text-decoration: none;
      display: block;
      margin: 0;
      font-family: $brand-font-family;
      text-transform: uppercase;
      @extend .font-600;
      @include font-size(30px);

      .headline-blue {
        color: $blue;
      }
    }
  }
}

/* Scaling the video */

@media screen and (max-width: 1290px) {
  .video-header-wrapper {
    .video-header-fg,
    .video-header-bg video {
      height: 137%;
      top: -22%;
      width: 150%;
      left: -25%;
    }
  }
}

@media screen and (max-width: 1470px) {
  .video-header-wrapper {
    .video-header-fg,
    .video-header-bg iframe,
    .video-header-bg video {
      position: absolute;
      pointer-events: none;
      height: 165% !important;
      top: -33% !important;
      width: 155% !important;
      left: -28% !important;
    }
  }
}

@media screen and (max-width: 2000px) {
  .video-header-wrapper {
    .video-header-fg,
    .video-header-bg iframe,
    .video-header-bg video {
      height: 165%;
      top: -30%;
      width: 150%;
      left: -25%;
    }
  }
}


@media screen and (max-width: 750px) {
  .video-header-wrapper .image-header-nobg,
  .video-header-wrapper img {
    width: 230% !important;
    top: -15%;
    width: 150%;
    left: -25%;
  }
}