.categoryPage {

	.application-category-intro {
		margin-top: -44px;
		margin-bottom: 44px;
		
		.application-category-title {
			color: $text-white; 
			@include font-size(24px);  
			margin: 0 0 50px 0;
		}
	}

	.thumbnail {
		@include thumbnail(172px, 172px, 60px);
	} 
}
