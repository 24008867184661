.product-category-heading {
	position: relative; 
	margin-top: -57px;
	@extend .font-OpenSans;
	
	.product-category-titles {
		position: relative;
		margin-top: -20px;
		
		.product-category-title-name, .product-category-title-download {
			color: $text-white; 
			@include font-size(24px);
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		}
			
		.product-category-title-name {
			margin-top: 32px;
			margin-bottom: 30px;
		}
		
		.product-category-title-download {
			margin-bottom: 0;
		}
		
		.product-category-download-parametrics {
			color: #ace0f0;
			@include font-size(14px);
			position: relative;
			padding-right: 24px;
			margin-bottom: 16px;
			float: left;
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
			
			.lnr {
				@include font-size(20px);
				@extend .font-300;
				//position: absolute;
				text-decoration: none;
				border: none;
				margin-left: 3px;
				right: 0;
				bottom: 0;
			} 
		}
	}
}

.product-category-detail {
	&.tabs {
		position: relative;
		z-index: 5;
		margin-top: 5px;
	}

}

@media screen and (max-width: $screen-xs-max) { 
	.tab-content {
		.block {
			padding: 24px 15px !important;
		}
	}
}