.subfooter-content-wrapper {
  padding: 10px 0;
  .newsletter-subscription-wrapper {
    .col-md-12 {
      padding: 0;

      .newsletter-subscription-text {
        margin: 20px 0;
        margin-top: 10px;
        @include font-size(13px);
        font-weight: 600;
      }
    }

    h4 {
      color: $text-black;
      @extend .font-600;
      transition: all 0.2s;
      margin: 20px 0;
    }

    form {
      .form-group {
        padding: 0;

        .invalid-email-tooltip {
          position: absolute;
          padding: 6px 12px;
          left: 20px;
          -webkit-box-shadow: 0 2px 3px rgba(0,0,0,.2);
          box-shadow: 0 1px 3px rgba(0,0,0,.4);
          background-color: #fff;
          z-index: 8;
          bottom: -36px;
          display: none;

          .invalid-email-arrow {
            position: absolute;
            width: 22px;
            height: 5px;
            top: -4px;
            left: 30px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #fff;
          }

          i {
            background-color: orange;
            padding: 4px 9px;
            color: #fff;
            position: relative;
          }
        }

        .form-control {
          padding-left: 16px;
          box-shadow: none;
          height: 46px;
          @include font-size(12px);
          font-weight: bold;
          width: 98%;
          color: $gray-dark;
          transition: all 0.2s;
        }
      }
    }

    button {
      background-color: $blue;
      color: #fff;
      transition: all 0.2s;
      position: relative;
      border: none !important;
      overflow: hidden;
      line-height: 34px;
      border-color: transparent;

      &:focus {
        border: 1px solid #fff;
        background-color: $blue;
        color: #fff;
        outline: none;
      }

      &:after {
        position: absolute;
        content: "";
        height: 2px;
        background-color: $blue;
        left: 0;
        width: 0;
        transition: all 0.4s;
        bottom: 0;
      }

      &:hover {
        border: 1px solid $lightestgray;
        background-color: #fff;
        color: $blue !important;
        box-shadow: inset 0px 0px 1px rgba(0,0,0,0.5);

        &:after {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .newsletter-subscription-wrapper {
    width: 100%;
    .newsletter-subscription-text {
      margin: 0 !important;
    }
    .form-group {
      margin-bottom: 9px !important;

      .form-control {
        padding-left: 11px !important;
        width: 100% !important;
      }
    }

    button {
      margin-bottom: 7px !important;
    }

    h4 {
      margin-bottom: 10px !important;
    }
  }
}