.chemical-content-detail {
	.intro {
		margin-bottom: 0;
		
		.intro-title {
			margin-bottom: 24px;
		}
	}

	h1 {
		font-size: 24px;
		@media (min-width: $screen-xs-max) and (max-width: $screen-sm-max) {
			text-align: center;
		}

		@media only screen and (max-width: 768px) {
			text-align: center;
		}
	}
	
	.table-responsive {
		.table-bordered {
			margin: 0;
		}
		
		display: inline-block;
		width: 100%;
		margin: 12px 0;
	}
	
	.table-bordered {
		margin: 12px 0;
		
		thead th {
			background: $lightestgray;
		}
		
		tr {
		
			&.lightgrey {
				td {
					background: $lightestgray;
				}
			}
			td {
				&.noborder {
					&.t {
						border-top: 0;
					} 
					&.b {
						border-bottom: 0;
					}
				}
				
				&.spacer {
					height: 24px;
					background: white;
				}
				
				a {
					color: $link-color;

					&:hover {
						color: $link-hover-color;
						text-decoration: $link-hover-decoration;
					}
				}
			}
		}
	}
}

