.news-overview {
	padding: 0;

	.media {
		margin-bottom: 30px;
	}
	
	.thumbnail {
		@include thumbnail(172px, 100%, 60px);
	}
}