.user-dropdown {
  display: inline-block;
  //width: 161px;
  z-index: 1;
  //position: absolute;
  padding-bottom: 15px;
  @media only screen and (max-width: 1250px) {
    padding-bottom: 15px;
    padding-right: 20px;
  }

  .fa {
    color: #ffffff;
  }

  &__my-ampleon {
    display: flex;
    a {
      color: #222222;
    }

    &--hidden {
      display: none;
      visibility: hidden;
    }
  }

  &__username {
    &--hidden {
      display: none;
      visibility: hidden;
    }
  }

  @media (max-width: 767px) {
    position: relative;
    width: 100%;
    padding: 0;
  }

  &__list {
    list-style: none;
    margin: 0;
    background: #003B71;
    padding: 0;
    display: none;
    position: absolute;
    top: 51px;
    width: 175px;
    @media (max-width: 767px) {
      position: relative;
      width: 100%;
      top: 0;
    }
  }

  &__list-anchor {
    color: #ffffff !important;
    text-decoration: none;
    padding: 0 !important;
    display: inline-block;
    margin: 20px 0;
    font-size: 12px;
    font-family: sans-serif;
    text-transform: uppercase;
    @media (max-width: 767px) {
      margin-left: 7px;
    }
  }

  &__list-item {
    margin: 0 20px;
    border-top: 1px solid #00a4e4;
    @media (max-width: 767px) {
      margin: 0;
    }
  }

  &__username-box {
    display: flex;
    //padding: 16px 0;
  }

  &__list--expanded {
    display: block;
    @media (min-width: 768px) and (max-width: 1250px) {
      margin-left: -50%;
    }
  }

  &__username-box {
    display: flex;
    align-items: center;
  }

  &__username-name,
  &__my-ampleon {
    color: #222222;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    @media only screen and (max-width: 1250px) {
      font-size: 11px;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      color: #fff;
      font-weight: normal;
      font-size: 11px;
    }
  }

  &__username-image {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    overflow: hidden;
    background: #00A8E1;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
  }
}

.navbar-form .form-group {
  background: white;
  z-index: 2;
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse.navbar-collapse {
    padding: 0;
  }
  .navbar-form.navbar-form {
    padding: 0;
  }
}