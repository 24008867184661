.row.promotional {
	background: $blue;
	color: $text-white;
	padding: 20px;  
	position: relative;
	overflow: hidden;
	margin: 0 15px 20px 15px;
	
	a {
		color: $text-white;
		text-decoration: none;
	}
	
	.col-md-8, .col-xs-8 {
		padding-left: 0;
		
		h3 {
			border-bottom: 0;
			margin: 0;
			
			&:hover {
				text-decoration: underline;
			}
		}
		
		.btn {
			@include btn-arrow(20px,'right');			
		}
	}
	
	.image {
	  position: absolute;
	  top: 0;
	  right: 0;
	  height: 100%;
	  background-size: cover;
	  background-position: center;
	}
}


@media screen and (max-width: $screen-xs-max) {
	.row.promotional {
		.col-xs-8 {
			width: 100%;
			padding-right: 0;
		}
		
		.image {
			width: 100%;
			height: 200px;
			margin: 0 0 20px 0;
			position: relative;
		}
		
		.btn {
			margin-top: 10px;
			width: 100%;
		}
	}
}