.designTool {
	#calculator-section {
		margin-top: 5px;
	}
	.page-title {
		margin-left: 0;
	}

	.design-tool {
		font-size: 80%;
		
		&-intro {
			.subtitle {
				font-weight: bold;
			}
			> .row {
				margin-top: 10px;
			}
		}

		.section-header {
			margin-bottom: 20px;
		}
		
		.unit {
			white-space: nowrap;
		}
		
		.tabs a {
			font-size: 14px;
		}

		#about-section {
			ul {
				li {
					display: inline;
					h2 {
						cursor: pointer;
						color: #337ab7;
					}
					h2:hover {
						text-decoration: underline;
					}
				}
			}
		}

		input, select {
			display: inline-block;
			height: 20px !important;
			font-size: 80% !important;
			padding: 0 2px;
		}

		#section-holder {
			#status-box {
				margin-bottom: 20px;
				border: solid #ccc 1px;
				padding: 0 30px;
				h2 {
					margin-top: 5px;
				}
				#hard-limit-error, #soft-limit-error {
					margin-bottom: 10px;
				}
			}

			.soft-limit-broken {
				background-color: yellow !important;
			}

			.hard-limit-broken {
				background-color: red !important;
				color: white !important;
			}

			#transistor-properties-holder, #input-holder {
				h3 {
					padding-top: 10px;
				}
			}

			.graphs-container {
				ul.graphs-tabs {
					display: table;
					list-style: none;
					margin: 0;
					padding: 0;
					li {
						display: table-cell;
						padding: 20px;
						cursor: pointer;
						&.current {
							background-color: #00a8e1;
							color: white;
						}
					}
				}
				.graph-tab-content {
					display: none;
					padding: 10px;
					&.current {
						display: inherit;
					}
				}
			}

			#section-A, #section-B, #sym, #output-a, #output-b, #output-sym {
				#eta-PAE-line {
					margin-bottom: 20px !important;
				}
			}

			.sections-container {
				margin: 20px 0;
			}

			input {
				text-align: right;
				margin-bottom: 15px;
			}

			#pulsed-cond-select {
				margin-bottom: 15px;
			}

			.fix-input-margin {
				margin-left: 15px;
			}

			select {
				height: 34px;
				border: 1px solid #ccc;
				transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
				&:focus {
					border-color: #66afe9;
					box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
					outline: 0 none;
				}
			}
		}

		.column-container {
			margin-bottom: 10px;
			border: solid #ccc 1px;
		}

		#output-container-a {
			input {
				margin-bottom: 15px;
			}
		}
	}
}
