.applications-products-container {
  padding: 10px 0;

  .applications-products-wrapper {
    width: 1170px;
    margin: 0 auto;
    height: auto;
    overflow: auto;
    display: table;
    transition: all 0.2s;

    .applications-products-list {
      display: table-cell;
      width: 100% !important;

      .nav-item {
        display: inline-block;
        width: auto;
        padding: 0px 12px;
        padding-left: 0;
        @include font-size(21px);

        a {
          color: $gray;
          border: none;
          transition: all 0.2s;
          font-weight: 600;
          background-color: transparent;
          padding-left: 0;

          &:hover {
            color: $blue;
            background-color: transparent;
          }
        }

        &.active * {
          color: $blue;
        }
      }

      .tab-content {
        .tab-pane {
          .sorted-items-wrapper {
            box-shadow: none !important;
            background-color: transparent !important;

            > a:not(.collapsed) {
              transition: all 0.2s;
              color: $blue;
              border: none;
            }

            a {
              cursor: pointer;
              text-decoration: none;
              color: $text-black;
              display: block;
              padding: 20px 0px;
              border-bottom: 1px solid $lightgray;
              font-weight: 600;
              transition: all 0.2s;

              &:hover {
                color: $blue;
              }
            }

            > a > i {
              float: right;
            }

            .sorted-items {
              ul {
                padding: 9px 27px;
                margin-bottom: 0;

                li {
                  list-style: none;
                  display: block;

                  &.active a:first-child {
                    color: $blue;
                  }

                  a {
                    text-decoration: none;
                    display: inline-block;
                    color: $darkergray;
                    @include font-size(12px);
                    padding: 8px 0px;
                    border: none;

                    &:hover {
                      color: $blue;
                    }

                    &:last-child {
                      border-bottom: 0;
                    }
                  }

                  i {
                    margin-left: 20px;
                    color: $darkergray;

                    &:hover {
                      color: $blue;
                    }
                  }
                }
              }

              :not(.in) {
                background-color: #fff !important;
              }
            }
          }
        }
      }
    }

    .applications-products-description {
      display: table-cell;
      float: none;
      vertical-align: middle;
      padding-right: 70px;

      h5 {
        @include font-size(21px);
        font-weight: 600;
      }

      p {
        padding-right: 70px;
        @include font-size(12px);
        @extend .font-500;
      }

      span {
        display: none;
        i {
          @include font-size(21px);
          color: #00a8e1;
          cursor: pointer;
          z-index: 5;
          -webkit-transition: all .2s;
          -o-transition: all .2s;
          transition: all .2s;
        }
      }

      a {
        text-decoration: none;
        padding: 8px 0px;
        border: none;
        color: $blue;
        @include font-size(12px);
        @extend .font-600;

        &:hover {
          color: $lighterblue;
        }
      }
    }
  }
}

/* Media queries */

@media only screen and (max-width: 1200px) {
  .applications-products-container {
    .applications-products-wrapper {
      width: 970px;
      display: block;

      .applications-products-description {
        width: 100% !important;

        display: block;

        p {
          max-height: auto;
          overflow: hidden;
          padding: 0;
        }
        span {
          display: block;
          text-align: center;
        }

      }

      .applications-products-list {
        display: block;

        .nav-item {
          padding-left: 0;

          .nav-link {
            padding-left: 0;
          }
        }

        .tab-content {
          .tab-pane {
            .sorted-items-wrapper {
              > a {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .applications-products-container {
    overflow: auto;
    padding: 10px 0;

    .applications-products-wrapper {
      padding-left: 15px;
      padding-right: 15px;
      width: 750px;

      .applications-products-list {
        padding: 0;
      }

      .applications-products-description {
        display: none !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .applications-products-wrapper {
    width: auto !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}