.banner {
  width: 100%;
  height: 262px;
  position: relative;
  background: #fff0;

  .banner-gradient {
    @extend %banner-gradient-bg;

    .container {
      position: relative;
      height: 100%;

      .row {
        position: relative;
        height: 100%;

        .xs-push {
          position: absolute;
          bottom: 0 !important;
          left: 0;
          z-index: 5;

          span.hidden {
            display: none;
          }

          .product-title-name {
            color: $text-white;
            @include font-size(24px);
            margin: 0 0 5px 0;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
            float: left;
            clear: both;
            width: 100%;
          }

          .product-download-datasheet {
            color: #ace0f0;
            @include font-size(14px);
            position: relative;
            padding-right: 24px;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
            margin-bottom: 10px;
            display: inline-block;
            float: left;

            .lnr {
              @include font-size(20px);
              @extend .font-300;
              //position: absolute;
              text-decoration: none;
              border: none;
              margin-left: 3px;
              right: 0;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

.main {
  .date {
    @include font-size(12px);
    font-style: italic;
    color: $text-black;
  }
}

.regularPage {
  .banner {
    height: 193px;

    .exclude-gradient {
      background-image: none;

      .page-title {
        margin-bottom: -10px;
        margin-left: 26%;

        h1 {
          text-shadow: none !important;
          font-size: 1.5rem !important;
        }

        h2 {
          text-shadow: none !important;
        }
      }

    }
  }
}

.nonRegularPage {
  .banner {
    height: 80px;
    background: none !important;

    .exclude-gradient {
      background-image: none;

      .page-title {
        color: #333333 !important;
        text-shadow: none !important;
      }
    }
  }

  .page-title {
    color: #333333 !important;
    text-shadow: none !important;
  }

  .product-download-datasheet {
    color: #00a8e1 !important;
    text-shadow: none !important;
  }

  .product-title-name, .package-title-name {
    color: $text-gray !important;
    text-shadow: none !important;
  }
}

.page-tagline {
  color: #fff;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .page-title {
    padding: 15px !important;
    top: inherit !important;
  }
}

.parametric-filters-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;

  .filters-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 8px 8px;

    &__filters-toggle {
      cursor: pointer;
    }

    span {
      width: 72px;
      min-height: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition-duration: .2s;

      &:hover {
        background: #00a8e1;
        color: #fff;

        i {
          color: #fff;
        }
      }

      i {
        color: #00a8e1;
        margin: 0 4px;
        font-size: 12px;
      }
    }

    h4 {
      font-size: 22px;
      margin: 0;
      line-height: 1;
    }
  }


  .filters-list {
    border-top: 1px solid #ddd;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: calc(742px - 70px);
    overflow-y: auto;

    &__item {
      border-bottom: 0.5px solid #ddd;

      &:last-child {
        border-bottom: none;
      }

      label {
        padding-left: 18px;
        font-size: 14px;
        line-height: 13px;
        font-weight: normal;
        margin-bottom: 0;
      }
    }

    &__item-name {
      display: block;
      cursor: pointer;
      font-weight: 600;
      line-height: 13px;
      padding: 11px 16px 9px 9px;
    }

    &__item,
    &__nested-list {
      display: block;
      float: left;
      width: 100%;
    }

    &__item-name--active {

      i {
        transform: rotate(180deg);
      }
    }

    &__nested-list {
      height: auto;
      overflow: visible;
      opacity: 1;
      padding: 0 16px 12px 9px;
    }

    &__nested-list--collapsed {
      height: 0;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &__nested-item {
      position: relative;

      .ui-slider {
        height: 2px;
        background-color: #B3E5F6;
        border: none;
        margin-bottom: 13px;
        width: calc(100% - 12px);
        position: relative;
      }
    }

    i {
      color: #000000;
      float: right;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -ms-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
    }

    #slider {
      margin-bottom: 13px;
      height: 2px;
      border: none;
      background: #B3E5F6;
    }

    .ui-slider-range {
      background: #00A8E1;
      height: 2px;
    }

    .ui-slider-handle {
      border: 2px solid #00A8E1;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #ffffff;
      top: -4px;
      margin-left: 0 !important;
    }

    &__slider-value-min,
    &__slider-value-max {
      width: 42px;
      height: 22px;
      display: inline-block;
      border: 1px solid #DDD;
      text-align: center;
    }

    &__slider-value-min {
      float: left;
    }

    &__slider-value-max {
      float: right;
    }
  }
}


.parametric-intro-paragraph {
  margin-bottom: 30px !important;
}

.custom-control {
  .bold-checkbox {
    font-weight: 700 !important;
  }
}

.sidebar {
  .sub-nav {
    margin-top: 35px;
  }
}


.parametric-intro {
  padding-top: 50px;
}

.nav-pills {
  margin-bottom: 30px !important;

  .target-apps {
    background-color: #00a8e1 !important;

    .ta-link {
      color: #ffffff !important;
    }

    .target-close {
      border: 0px;
      background-color: #00a8e1;
    }
  }

  .nav-item {
    border: 1px solid #00a8e1;
    height: 34px;

    a {
      color: #00a8e1 !important;
      padding: 6px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .page-title {
    padding: 15px !important;
    top: inherit !important;
  }
}

.parametric-search {
  margin-bottom: 60px;
  padding-top: 30px;
  border-top: 1px solid #DDD;

  .ps-controls {
    width: 100%;
    float: left;
    margin-bottom: 15px;

    > .pull-left {
      position: relative;

      .parameters {
        margin-left: -198px;
        top: 72px;
      }

      .close-parameters {
        left: 0;
        right: auto;
      }
    }
  }

  &__overview {
    position: relative;
  }

  &__controls {
    float: left;
    width: 100%;
  }

  &__col--collapsible {
    float: left;
    width: 270px;
    transition: width 0.2s;
  }

  &__col--collapsed {
    width: 72px;


    .filters-head {
      padding: 0 !important;
    }

    .filters-head h4 {
      display: none;
    }

    .filters-head__filters-toggle {
      border-bottom: 1px solid #ddd;
    }

    .filters-list {
      background-color: #ffffff;
      opacity: 0;

      a {
        cursor: default;
      }
    }
  }

  &__col--fixed {
    float: left;
    width: 355px;
    border-right: 1px solid #dddddd;
  }

  &__results {
    width: calc(100% - 270px);
    float: left;
    overflow-x: hidden;
    transition: width 0.2s;

    &--full-width {
      width: calc(100% - 72px);
    }

    &-content {
      overflow-x: auto;
      overflow-y: auto;
    }
  }

  table {
    border-collapse: inherit;
  }

  .table {

    &__body,
    &__head {
      display: block;
    }

    &__th,
    &__cell {
      border: none;
      display: table-cell;
      vertical-align: top;
      padding: 13px;
    }

    &__th {
      min-height: 71px;
      height: 71px;
      font-weight: 400;

      &:not(.table__element--fixed) {
        cursor: pointer;
      }
    }

    &__cell {
      min-width: 100px;
      min-height: 71px;
      height: 71px;
    }

    &__cell-cta-wrapper {
      display: inline-block;
      float: left;
      width: 40px;
      position: absolute;
      top: 29px;
      right: 10px;
    }

    .custom-checkbox__input,
    .custom-checkbox__checkmark {
      top: 29px !important;
      transform: translateY(0) !important;
    }

    &__cell-cta-download {
      margin-right: 10px;
    }

    &__cell-anchor {
      display: block;
      color: #337ab7;
      font-weight: 600;
      cursor: pointer;
      line-height: 20px;

      &:hover {
        text-decoration: none;
      }
    }

    &__thead {
      display: block;
      border-top: 1px solid #ddd !important;

      th:first-child {
        z-index: 3 !important;
        padding-left: 8px;
      }
    }

    &__tbody {
      display: block;
      max-height: 635px;
    }

    &__th {
      position: sticky;
      top: 0 !important;
      left: 0;
      background-color: #ffffff !important;
      z-index: 2 !important;

      &-content--left {
        height: 100%;
        float: left;
        color: #00A8E1;
        margin-right: 16px;
        font-weight: 600;
      }

      &-content--right {
        position: relative;
        display: inline-block;

        span {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 3px;
          display: inline-block;
          font-weight: 300;
        }

        .table__th-sort-icons {
          top: 2px;
          right: 6px;
        }
      }
    }

    &__th-sort-icons {
      display: inline-block;
      float: right;
      position: absolute;
      right: 0;
      top: 13px;

      i {
        position: absolute;
        right: 0;
      }

      i.inactive {
        color: #dddddd;
      }
    }

    &__cell,
    &__th {
      min-width: 100px;
      border-top: 1px solid #ddd;
    }

    &__element--fixed {
      left: 0;
      position: sticky !important;
      top: auto !important;
      max-width: 355px;
      min-width: 355px;
      z-index: 1 !important;
      display: table-cell;
      padding: 10px 16px 10px 36px;
      border-right: 1px solid #ddd;
    }

    &__cell-meta-info {
      font-size: 14px;
      width: 200px;
      display: block;
      float: left;
      line-height: 17px;
      margin-left: 37px;

      span {
        font-size: 12px;
      }
    }

    &__cell-actions {
      display: block;
      float: left;
      height: 100%;
      width: 50px;
    }

    &__input {
      border: 0.5px solid #DDD;
      height: 30px;
      padding: 0 9px;
      font-weight: 300;

      &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: #F2F2F2;
      }

      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #F2F2F2;
        opacity: 1;
      }

      &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #F2F2F2;
        opacity: 1;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #F2F2F2;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #F2F2F2;
      }

      &::placeholder { /* Most modern browsers support this now. */
        color: #F2F2F2;
      }
    }
  }

  .table-striped > tbody > tr:nth-of-type(odd) .table__element--fixed {
    background-color: #f9f9f9;
  }

  .table-striped > tbody > tr:nth-of-type(even) .table__element--fixed {
    background-color: #ffffff;
  }

  //icons
  .icon {
    background-size: 12px;
    height: 12px;
    width: 12px;
    display: inline-block;
  }

  .icon__download {
    background-image: url('../images/download-icon.png');
  }

  .icon__shopping-cart {
    background-image: url('../images/shopping-cart-icon.png');
  }

  // forces ul not to display list-style (circles) in firefox MYA-130
  ul {
    list-style: none;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  _:-ms-input-placeholder, :root .table__cell-meta-info {
    position: relative;
  }

  _:-ms-input-placeholder, :root .table__cell-cta-wrapper {
    top: 18px;
    right: -50px;
  }
}

.modal-backdrop.fade {
  background-color: rgba(0, 59, 113, 0.6) !important;
}

.regularPage {
  .document-filter.document-public {
    margin: 0;
    padding-left: 0;
  }

  .document-table.document-public {
    margin: 0;
    padding-left: 0;
  }

  .document-portfolio.document-public {
    margin: 0 0 25px;
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .myAmpleon .my-documents .document-filter {
    margin-left: 15px;
    margin-right: 15px;
  }

  .myAmpleon .my-documents .document-table {
    margin-left: 15px;
    margin-right: 15px;
  }

  .myAmpleon .my-documents .document-portfolio {
    margin-left: 15px;
    margin-right: 15px;
  }

  .myAmpleon .pagination .VuePagination__count {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media only screen and (max-width: 768px) {
    .pagination-wrapper nav {
      margin-left: 0 !important;
    }
  }
}


.VuePagination .pagination.VuePagination__pagination {
  // this rule forces the paginator to display even when there's "only one page"
  display: flex !important;
  @media (max-width: 767px) {
    // then center it on mobile
    justify-content: center !important;
  }
}

@media (min-width: 767px) {
  .my-admin .show-results .results-wrapper {
    margin-top: -60px;
  }
}

.main-content-wrapper {
  .container {
    width: 100% !important;

    .col-md-12 {
      padding: 0;
    }
  }
}
