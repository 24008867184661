.product-detail {
	.product-heading {
		position: relative; 
		margin-top: -66px;
		@extend .font-OpenSans;
	
		.product-visual {
			float: right;
			background: white; 
			border: 1px solid $lightgray; 
			text-align: center; 
			width: 100%; 
			margin-bottom: 20px;
			height: 0;
			padding-bottom: 100%;
			display: table-cell; 
			vertical-align: middle;
			position: relative;
			z-index: 4;
			min-height: 50px;
			
			img {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
			}
		}
	
		.tabs {
			margin-top: 20px;
		}
	}
	
	.xs-pull {
		margin-top: 72px;
	}
	
	.tab-content {
		margin-top: 20px;
	
		.product-details-block {
			padding: 0 0 24px 0;
			
			&.technical-summary {
				@include font-size(16px);
				@extend .font-400;
			}
		}
		
		ul, .ul {
			padding-left: 10px;
			list-style-type: none;
			
			li, .li {
				@include font-size(14px);
				padding-left: 10px;
			    text-indent: -17px;
				
				&:before {
				    content: "•";
				    color: $blue;
					@include font-size(18px);
					line-height: 18px;
					padding-right: 10px; 
				}
			}
		}
		
		#design-tool-form {
			input[name=temp] {
				padding: 6px 0 6px 12px;
			}
			
			.temp-unit {
				padding: 6px 0 0 6px;
			}
			
			#submitDesignToolForm {
				margin-top: 20px;
			}
		}
	}
}

.product-data {
	position: relative;
	z-index: 5;
	
	.product-title-name {
		color: $text-white;
		@include font-size(24px);
		margin: 0 0 5px 0;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		float: left;
		clear: both;
		width: 100%;
	}
	
	.product-download-datasheet {
		color: #ace0f0;
		@include font-size(14px);
		position: relative;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		margin-bottom: 10px;
		display: inline-block;
		float: left;
		
		.lnr {
			@include font-size(20px);
			@extend .font-300;
			//position: absolute;
			text-decoration: none;
			border: none;
			margin-left: 3px;
			right: 0;
			bottom: 0;
		}
	}
	
	&.visible-xs {
		text-align: center;
		
		.product-title-name {
			color: $text-black;
			text-shadow: none;
			margin-top: 20px;
		}
		
		.product-download-datasheet {
			text-shadow: none;
			color: $blue;
			text-align: center;
			float: none;
			width: 100%;
		}
	}
}

@media screen and (min-width: 320px) and (max-width: $screen-xs-max) {
	.product-data {
		&.visible-xs {
			
			.product-title-name {
				color: $text-black;
				text-shadow: none;
				margin-top: 20px;
			}
			
			.product-download-datasheet {
				text-shadow: none;
				color: $blue;
				float: left;
			}
		}
	}
}

@media screen and (max-width: $screen-xs-max) {
	.product-detail {
		margin-top: 10px;
		
		.product-heading {
			
			.product-visual {
				margin-bottom: 0;
				padding: 0;
				display: table !important;
			
				img {
					position: static;
				}
			}
		
			.xs-push {
				padding-left: 0 !important;
				
				a.product-download-datasheet {
					float: left !important;
				}
			}
			
			.xs-pull, .tabs {
				margin-top: 20px;
			}
		
			.visible-xs {
				.bluelightest.small-text {
					text-shadow: none !important;
					color: $blue;
					float: left !important;
					width: 100%;
					
					a {
						color: $blue;
						text-decoration: underline;
					}
				}
			}
		
			.tab-content {
				.block {
					padding: 24px 15px !important;
				}
			}
		}	
	}
}