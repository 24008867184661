.featured-products {
	.featured-product {
		h4 {
			margin-top: 0;
		}
		
		a {
			color: $blue;
			position: relative;
						
			.lnr {
				@include font-size(20px);
				padding-left: 8px;
				position: absolute;
				right: -24px;
				bottom: -2px;
			}
		}
		
		margin-bottom: 30px;
	}
}