.downloadPage {

  .page-title {
    font-size: 24px;
  }

  .meta-item {
    padding-right: 2px;
    display: inline-block;
    font-weight: bold;
  }

  .meta-item::after {
    content: ' |';
    color: #ccc;
  }

  .metadata{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 10px;
    color: black;
  }

  .downloadIcon{
    margin: 0 0 10px;
  }
}
