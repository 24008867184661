.myAmpleon.nonRegularPage {
  padding-right: 0 !important;
}

.my-admin {

  .my-admin__title {
    margin-top: 35px;

    .page-title.my-admin__title-heading {
      color: #000 !important;
      line-height: 29px;
      margin-bottom: 36px;

      @media (max-width: 767px) {
        padding: 15px 0 !important;
      }
    }
  }

  .document-filter {
    margin: 0 60px;
    padding: 0 15px;

    @media (max-width: 991px) {
      margin: 0;
      padding: 0 25px;
    }

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  .document-table {
    margin: 0 60px 60px 60px;
    padding: 0 15px;

    @media (max-width: 991px) {
      margin: 0 0 60px 0;
      padding: 0 25px;
    }

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  .document-query {
    border-color: #ddd;
    box-shadow: none;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: 767px) {
      width: 100%;
    }

    &::-webkit-input-placeholder { /* Edge */
      color: #000;
      font-size: 14px;
      font-weight: lighter;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #000;
      font-size: 14px;
      font-weight: lighter;
    }

    &::placeholder {
      color: #000;
      font-size: 14px;
      font-weight: lighter;
    }
  }

  .top-bar {
    background-color: #003b71;
    height: 60px;
    width: 100%;
    top: 50px;
    left: 0;
    z-index: 2;
    overflow: hidden;
  }

  .form-inline {
    margin-bottom: 0;

    .form-group.second-group-buttons {
      margin-right: 0;
    }
  }

  .form-query__col {
    margin-bottom: 36px;

    .form-group__input {
      margin-right: 12px;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }

  .document-search {
    margin-right: 4px;
  }

  .VuePagination__pagination {
    @media (max-width: 576px) {
      width: 100%;
      text-align: center;
    }
  }

  .pagination>li>a,
  .pagination>li>span {
    @media (max-width: 767px) {
      float: none;
    }
  }

  .pagination,
  .VuePagination__count {
    margin: 0 0 36px 0;
  }

  .VuePagination__count {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .admin-history {
    margin-right: 4px;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 5px;
      width: 100%;
    }
  }

  .user-info {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .page-results-paragraph {
    margin: 0 10px 0 0;
  }

  .results-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //margin-top: -56px;
    //left: 289px;
    max-width: 270px;

    @media (max-width: 767px) {
      left: 0;
      margin: 0 0 36px 0;
    }
  }

  .table-responsive {
    border: none;
  }

  .table>thead>tr>th {
    border-bottom: 0;
    color: #000;
    background-size: 21px 13px;
  }


  .table>thead>tr>th.headerSortUp,
  .table>thead>tr>th.headerSortDown {
    background-size: 26px !important;
  }

  .table>thead>tr {
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }

  .table.documents {
    min-width: 1000px;
    margin-bottom: 0;
  }

  tbody tr {
    border-bottom: 1px solid #DDDDDD;
  }

  @media (max-width: 767px) {
    .VuePagination nav {
      margin-left: 0 !important;
    }
  }

  .first-group-buttons {
    @media (max-width: 767px) {
      margin: 0 0 5px 0 !important;
    }

    .document-search {
      @media (max-width: 767px) {
        width: calc(50% - 2px);
      }
    }

    .document-reset {
      @media (max-width: 767px) {
        width: calc(50% - 2px);
      }
    }
  }

  .second-group-buttons {
    float: right;

    @media (max-width: 767px) {
      float: none;
    }
  }

  .text-blue {
    color: #337AB7;
    font-weight: bold;
  }

  .form-check-label {
    font-size: 14px;
  }

  .bold-style {
    font-weight: bold;
  }

  .form-check-label {
    margin-left: 5px;
  }

  .custom-select {
    padding: 5px 25px 5px 8px;
    color: #387EB9;
    margin-top: -7px;
    border: 1px solid #DDD;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    -webkit-appearance: none;
    background: url('/.resources/ampleon-theme/img/asc.gif') center right no-repeat;
    width: 54px;
    height: 34px;
    background-size: 25px;

    &::-ms-expand {
      display: none;
    }
  }

  ul input {
    margin-top: 0 !important;
  }

  .form-check-label {
    margin-left: 5px;
  }
}