.search-results {
	.list-group {
		.list-group-item {
			margin-bottom: 15px;
			
			a {
				color: $blue;
				
				&.icon, &.download {
					color: $blue;
					border-bottom: none;
					position: relative;
					
					&:hover, &:focus {
						border-bottom: none !important;
						text-decoration: none !important;
					}
					
					.lnr {
						@include font-size(20px);
						color: $blue;
						vertical-align: middle;
						position: relative;
					}
				}
				
				&.download {
					.lnr {
						bottom: 0px;
						transition: all 0.2s ease-in-out 0s;
						
						&:hover, &:focus {
							bottom: -2px;
						}
					}
				}
			}
			
			h4 a {
				color: $text-black;
			}

			.product-anchors {
				list-style-type: none;
				padding: 0px;

				li {
					float: left;

					a {
						padding-right: 16px;
						text-decoration: underline;
						color: gray;
					}

					button {
						border: 0px;
						padding: 0px;
						text-decoration: underline;
						color: gray;
					}
				}
			}
		}
	}
}