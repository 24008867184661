.promoted-headline {
  border-bottom: none;
  margin: 40px 0 0 0;
  font-size: 1.15em;
  font-weight: 700;
  padding-bottom: 10px;
}

.promo-headline{
  margin: 24px 0 15px 15px;
  font-weight: 400 !important;
}

#promoted-tiles{
  h3{
    border-bottom: none !important;
  }
}

@media only screen and (max-width : 768px) {
  .promoted-tiles-row{
    .item-mobile{
      margin-bottom: 20px;
    }
  }
  nav{
    margin-left: 45px !important;
  }
}

@media only screen and (min-width : 1024px) {
  .promoted-tiles-row{
    margin: 0 !important;
    padding: 0 !important;
    li{
      .item-desktop{
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 20px;
        padding: 0 !important;
      }
    }
  }
}