html {
	font-size: 16px;
}

.navbar {
	background-color: white !important;
	margin-bottom: 0;
	z-index: 99;
	border: none;
	// using important because there is !important margin-left for <nav> in _promotedTiles.scss (line 27)
	// i don't want really want to remove it because might just break random stuff - Antonio
	@media (min-width: 768px)  {
		margin-left: 0 !important;
	}

	.navbar-header {
		padding: 0;
		.logo {
			padding: 5px !important;

			img {
				height: 50px;
			}
		}
	}
	li {
		.no-js-nav &.dropdown:hover {
			.dropdown-menu {
				display: block;
			}
		}
		&.active {
			a, a:hover {
				background: transparent;
				color: $darkblue;
			}
		}

		&.dropdown.open {
			a.dropdown-toggle {
				background: white;
				color: $darkblue;
			}
		}

		a {
			position: relative;
			padding-top: 16px;
			color: $text-black;
		}

		div.arrow-up {
			position: absolute;
			top: 60px;
			left: 50%;
			margin-left: -15px;
			width: 30px;
			height: 8px;
			overflow: hidden;
			@include opacity(0);
			@include transition(all 0.1s ease);

			div {
				&:before {
					content: " ";
					display: block;
					height: 18px;
					width: 18px;
					position: absolute;
					border-radius: 25%;
					transform: rotate(45deg);
					border-left: 1px solid $darkblue;
					border-top: 1px solid $darkblue;
					left: 5px;
					top: 2px;
					background: $darkblue;
					@include transition(all .3s ease-in-out);
					@include opacity(1);
				}
			}
		}

		&.open, .no-js &:hover, &.show-arrow {
			.arrow-up {
				top: 52px;
				@include opacity(1);
				@include transition(all 0.1s ease);
			}
		}
	}
}

.topbar .dropdown-menu {
	background-color: $darkblue;
	color: $text-white;
	width: 100%;
	position: fixed;
	top: 60px;
	left: 0;
	z-index: 999;
	@extend .font-OpenSans;
	padding: 20px 0 0 0;
	border-top: 0 !important;
	border-radius: 0;


}
//}
.top-bar .container .sub.row {
	padding-top: 20px;
	
	.col-md-3 {
		padding-bottom: 20px;
		
		a:not(:first-child) .category-title {
			margin-top: 15px;
		}
	}
}
body .header .navbar .dropdown-menu,
.top-bar .container {
	.category-title {
		@extend .font-600;
		margin-bottom: 5px;
		float: left;
		clear: both;
		color: $text-white;
		padding-right: 24px;
		position: relative;

		.lnr {
			font-size: 20px;
			color: $blue;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -10px;
		}
	}

	ul {
		padding: 0 0 0 15px;
		float: left;
		clear: both;
		list-style-type: disc;

		li {
			color: $blue;
			padding: 0;

			a {
				@extend .font-400;
				color: $text-white;
				padding: 0;


				.total-products {
					color: $lighterblue;
				}
			}
		}
	}
}

.navbar {
	.search-form {
		float: right;
		transition: all 0.2s;
		padding-right: 0;

		.navbar-form {
			padding-right: 0;

			.has-feedback {
				.form-control {
					padding-right: 0;

					&.open {
						padding-right: 30px;
					}
				}
			}

			#nav-search {
				width: 0;
				margin-right: 30px;
				transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);

				&.open {
					width: 150px;
					border: 1px solid #ccc;
				}
			}

			.submit-btn {
				position: absolute;
				left: -9999px;
				width: 1px;
				height: 1px;
			}
		}

		&.hidden-xs {
			position: relative;

			.navbar-form .has-feedback {
				position: absolute;
				right: 0;
				background: white;
			}
		}

		&.visible-xs-block {
			.navbar-form {
				.submit-btn {
					right: 5px;
					left: auto;
					background: none;
					border: none;
					position: absolute;
					top: 6px;
					color: $text-white;
					width: auto;
					height: auto;
				}
				.expand-search {
					display: none;
				}
				#nav-search.open {
					width: 100%;
				}
			}
		}

	}

	.navbar-form {
		float: right;

		#nav-search {
			border: none;
			background-color: transparent;
			box-shadow: none;
			width: 200px;
			display: block;

			&:focus {
				border: 1px solid #ccc;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
			}
		}

		.expand-search {
			font-size: 18px;
			top: 8px;
			color: $darkblue;
			cursor: pointer;
			float: right;
			position: absolute;
			right: 5px;
		}
	}
}
//}
.navbar-default .navbar-toggle {
	border: none;
	background-color: transparent !important;
	margin-top: 10px;
	bottom: -2px;
}

.navbar-toggle {
	.icon-bar {
		height: 1px;
		transition: all .2s linear;
		transform-origin: center;
	}

	.icon-bar {
		transform: rotate(45deg) translateX(5px) translateY(2px);
		opacity: 1;

		& + .icon-bar {
			transform: rotate(0) translateX(2px) translateY(0px) scaleX(1.2);
			opacity: 0;
		}

		& + .icon-bar + .icon-bar {
			transform: rotate(-45deg) translateX(5px) translateY(-2px);
			opacity: 1;
		}
	}

	&.collapsed {
		.icon-bar {
			transform: rotate(0deg) translateX(0px) translateY(0px);
			opacity: 1;

			& + .icon-bar {
				transform: rotate(0) translateX(0px) translateY(0px) scaleX(1);
				opacity: 1;
			}

			& + .icon-bar + .icon-bar {
				transform: rotate(0deg) translateX(0px) translateY(0px);
				opacity: 1;
			}
		}
	}
}

/* Media queries */

@media only screen and (max-width: 1250px) {
	.navbar {
		padding: 0 !important;

		#nav-main {
			float: left !important;
			transition: all 0.2s;

			.nav {
				li {
					a {
						font-size: 11px;
						padding-left: 7px;
						padding-right: 7px;
					}
				}

				li:nth-child(4):after {
					right: -2px;
				}
			}
		}

		.search-form {
			padding: 0 !important;
			width: 0 !important;
		}
	}
}

@media only screen and (min-width: 768px) {
  .search-form {
    display: block !important;
  }

	#nav-main {
		margin-top: 0 !important;
	}
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  .navbar {
    .container {
      .navbar-brand {
        margin-left: 0;
      }
    }
  }
}


@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .navbar {
    .container {
      .navbar-brand {
        margin-left: -5px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
	.navbar	{
		.container {
			.navbar-brand {
				padding-left: 15px;
                margin-left: -45px;
			}
			.search-form {
				height: 50px;
				width: 100% !important;
				display: none;

				form {
					float: none;
					width: 100%;
					height: 100%!important;
					margin-top: 0!important;
					margin-left: 0!important;
					padding-left: 0;
					padding-top: 8px;
					padding-right: 0;

					.form-group {
						width: 100% !important;

						input {
							width: 100% !important;
						}

						#nav-search {
							border: 1px solid #e8e8e8;
							padding-left: 7px;
						}
					}
				}
			}
			.navbar-collapse {
				background-color: $darkblue;
				border-top: 2px;
				ul {
					margin: 0;
					li {
						a {
							color: #fff;
							padding: 20px 0;
							transition: all .1s linear;
							display: block;

							&:hover, &:active, &:focus {
								color: $blue;
							}
						}
					}
				}
			}

			.navbar-collapse {
				li:last-child {
					border-bottom: none;
				}
			}

			li {
				&:active {
					a {
						color: white;
						font-weight: 600;
					}
					a:hover {
						background: transparent;
						color: $blue;
					}
				}
			}

			div.visible-xs-block, div.visible-sm-block {
				margin: 0;
			}
		}
	}

  .dropdown-menu {
    position: static;
    display: none;
    float: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    overflow: hidden;
    border-top: 1px solid $lighterblue;
    padding: 0 20px 10px 20px;

    .container {
      padding: 0;
    }
  }

  .header {
    &.navbar-fixed-top	{
      position: relative !important;
      .navbar-collapse {
        max-height: none !important;
      }
    }
    .dropdown-menu {
      position: static;
      display: none;
      float: none;
      background-color: transparent;
      border: none;
      box-shadow: none;
      overflow: hidden;
      border-top: 1px solid $lighterblue;
      padding: 0 20px 10px 20px;

      .container {
        padding: 0;
      }
    }

    .navbar-form {
      border: none;
      box-shadow: none;
      width: 100%;
      margin: 0;
      padding: 13px 0;

      .has-feedback {
        display: block;
      }

      .submit-btn {
        right: 5px;
        background: none;
        border: none;
        position: absolute;
        top: 6px;
        color: $text-white;
      }

    }
    .dropdown-trigger .lnr {
      padding: 10px;
    }
  }

  body .header .navbar .dropdown-menu ul {
      padding-left: 20px;
  }

  .navbar-nav > li {
      border-bottom: 1px solid $lighterblue;
	  min-height: 60px;
  }
}

.navbar-default{
  border-color: #ffffff !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@media only screen and (min-width : 1200px) {
	.myAmpleon {

		.navbar .container {
			margin: 0 60px;
			width: auto;
		}
	}
}