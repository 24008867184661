.image-with-caption {
	@extend .font-OpenSans;
	position: relative;
	margin-bottom: 24px;
		
	&.pull-left {
		float: left;
		margin: 0 20px 20px 0;
	}
	
	&.pull-right {
		float: right;
		margin: 0 0 20px 20px;
	}
	
	.image {
		position: relative;
		
		img {
			display: block;
			width: 100%;
			outline: 1px solid #eee;
		}
		
		.lnr {
			color: $text-white;
			background-color: $blue;
			padding: 5px;
			@include font-size(20px);
			position: absolute;
			bottom: 0;
			left: 0;
			cursor: pointer;
		}
	}
	
	.caption {
		color: $blue;
		@include font-size(12px);
		display: block;
		margin: 4px 0 10px 0;
		line-height: 1.1rem;
		width: 100%;
		font-weight: 600;
	}
}
