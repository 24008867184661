.form-inline {
	margin-bottom: 15px;
	
	.form-group {
		margin-right: 15px;
		
		.control-label {
			margin-bottom: 5px;
		}
	}
}

div.contact {
	.selectpicker {
		display: none;
	}

	.dropdown-toggle {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	}
	
	.contact-phone {
		display: none;
	}

	#addAttachment[disabled] {
		cursor: default !important;
	}

	.remove-upload-field {
	    height: 25px;
	    left: -15px;
	    margin-top: 1px;
	    padding: 0;
	    position: absolute;
	    width: 25px;
	}
	
	.remove-upload-field:before {
		content: '-';
	}

	.remove-first-upload-field {
	    height: 25px;
	    left: -15px;
	    margin-top: 1px;
	    padding: 0;
	    position: absolute;
	    width: 25px;
	}
	
	.remove-first-upload-field:before {
		content: '-';
	}
	
	.exception {
		color: red;
	}
}


/* sharp spring form styling */

input[type="text"] {
	background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    color: #555;
    display: block;
    font-size: 14px;
    height: 34px;
    line-height: 1.42857; 
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    
    &:focus {
    	border-color: #66afe9;
	    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
	    outline: 0 none;
    }
}

ul.form_fields {

	li.field {
		max-width: 600px;
		
		label {
			display: inline-block;
		    font-weight: 700;
		    margin-bottom: 5px;
		    max-width: 100%;
		    font-family: "Open Sans",Arial,sans-serif !important;
		    font-size: 14px;
		    line-height: 1.42857;
		}
		
		&.checkbox {
			
			label {
				padding-left: 0;
				
				&.error {
					float: right;
					padding: 2px 6px;
				}
				
				&.checkbox-label {
					padding-left: 20px;
				}
			} 
			
			input[type='checkbox'] {
				margin-left: 0 !important;
			}
		}
	}
}
