.btn {


	&.ampleon {
		@extend .font-OpenSans;
	
		border-radius: 0;
		padding: 12px;
		line-height: 100%;
		text-align: left;
		white-space: initial;
	
		&.transparent {
			background: transparent;
			border: 1px solid $text-white;
			color: $text-white;
		}
		
		&.cta {
			background: transparent;
			border: 1px solid $blue;
			color: $blue;
			margin: 0 0 0 10px;
			
			@include btn-arrow(20px,'right');			
						
			&.download, &.order {
				@include btn-arrow(20px,'left');
			}
			
			
		}
	}
}

.cta-wrapper {
	width: 100%;
	clear: both;
	float: left;
	text-align: right;
	
	padding: $componentPadding;
}


@media screen and (max-width: $screen-xs-max) { 
	.cta-wrapper {
		.btn.cta {
			width: 100%;
			margin-left: 0;
			margin-bottom: 15px;
		}
	}
}

#submit_button.button {
    background-color: #337ab7;
    border-color: #2e6da4;
    color: #fff;
    -moz-user-select: none;
    background-image: none;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 6px 12px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    background-size: 100% auto;
    font-family: "Open Sans",Arial,sans-serif;
    text-shadow: none;
    -webkit-box-shadow: none !important;
    
    &:hover {
		background-color: #286090;
		border-color: #204d74;
		text-decoration: none;
    }
}

.btn-primary{
	background-color: #00a8e1 !important;
	border-color: #00a8e1 !important;

	&:hover{
		color: #337ab7 !important;
		background-color: #fefefe !important;
		border-color: #e2e2e2 !important;
	}
	&:active{
		color: #fff !important;
		background-color: #00a8e1 !important;
		border-color: #00a8e1 !important;
	}
	&:focus{
		color: #fff !important;
		background-color: #00a8e1 !important;
		border-color: #00a8e1 !important;
	}
}

.pagination{
	> .active > a,
	> .active > span {
		&,
		&:hover,
		&:focus {
			background-color: #00a8e1 !important;
			border-color: #00a8e1 !important;
		}
	}
}




