#product-portfolio {
	padding: $componentPadding;
	float: left;
	width: 100%;
	
	ul.product-portfolio-categories {
		@extend .tabs;
	}
	
	.product-portfolio-category {
		position: relative;
		height: 300px;
		border: 1px solid #d2d3d5;
		margin-bottom: 20px;
		padding: 20px;
		display: none;
		
		&.active {
			display: block;
		}
		
		.gradient-brand {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%; 
		}
		
		h4 {
			position: absolute;
			bottom: 128px;
			left: 20px;
					
			a {
				text-decoration: none;
				color: $text-white;
				text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
				@include font-size(24px);
				@extend .font-300;
				
				.lnr { 
					@include font-size(20px);
					margin: -5px 0 0 5px;
					text-decoration: none;
				}
			}
		}
		
		.subcategories {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
					
			.level {
				width: 100%;
				
				table {
					width: 100%;
					table-layout: fixed;
					border-collapse: collapse;
					border: none;
					background: transparent;
					margin-bottom: -1px;
					
					&+table {
						margin-top: -1px;
					}
					
					td {
						vertical-align: middle;
						border: 1px solid #d2d3d5;
						@include font-size(14px);
						
						&:first-child {
							border-left: 0;
						}
						
						&:last-child {
							border-right: 0;
						}
						
						a {
							@include transition(background 0.3s ease);
						}
						
						&:hover {
							border: 0;
							border-top: 1px solid rgba(0,164,228,0.9);
							
							a {
								background: rgba(0,164,228,0.9);
								color: $text-white;
								outline: 0;
								
								&:hover, &:focus, &:active {
									outline: 0;
								}
								
								.lnr { 
									color: $text-white;
								}
								
								.total-products {
									color: #4cc8e9;
								}
							}
						}
						
						a {
							padding: 20px;
							display: block;
							height: 90px;
							text-decoration: none;
							background: $text-white;
							color: $text-black;
							position: relative;
							line-height: 120%;
							background: $text-white;
														
							.label-category {
								width: 150px;
								display: inline-block;
							}
							
							.total-products {
								display: inline-block;
								color: $blue;
							}
							
							.lnr { 
								@include font-size(20px);
								text-decoration: none;
								position: absolute;
								right: 20px;
								top: 50%;
								margin-top: -10px;
								color: $blue;
							}
						}
						
						&.multilevel {
							a {
								height: 60px;
								padding: 12px 20px;
							}
						}
					}
				}
				
				.item {
					display: none;
				}
			}
		}	
	}
}	


@media screen and (max-width: $screen-xs-max) {
	.product-portfolio-category-xs {
		padding: 15px 15px 0 15px;
		border: 1px solid $gray;
		background-color: $lightestgray;
		margin-bottom: 15px;
	
		h4 {
			margin-top: 0;
			
			a {
				display: block;
				width: 100%;
				text-decoration: none;
				color: $blue;
			}
		}
	
		.subcategories {
			padding: 0;
			list-style-type: none;
			margin: 0;
			
			li {
				margin: 0 -15px;
				padding: 0;
				list-style-type: none;
				border-bottom: 1px solid #e7e7e8;
				
				&:last-child {
					border-bottom: 0 !important;
				}
				
				&:hover {
					background-color: $blue;
					
					a {
						color: $text-white;
						
						.total-products {
							display: inline-block;
							color: #4cc8e9;
						}
					}
				}
								
				a {
					width: 100%;
					display: block;
					padding: 15px;
					text-decoration: none;
					color: $text-black;
					
					.total-products {
						display: inline-block;
						color: $gray;
					}
				}
			}
		}
	}

}
