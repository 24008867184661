.product-comparision {

  .parametric-intro {
    width: calc(100% - 15px);
  }

  .btn-default {
    border-color: $blue;
    color: $blue;
    transition: background $transitionspeed;

    &:visited {
      border-color: $blue;
      color: $blue;
      background-color: white;
    }

    &:hover, &:focus {
      background: $blue !important;
      border: 1px solid $blue !important;
      color: white !important;
    }

    &.active, &:active {
      background-color: $blue !important;
      color: white !important;
      border-color: $blue;
    }
  }
}

.product-comparision__row {
  width: 1474px;
  margin: 0;
}

.product-comparision__btn-group {
  width: 127px;
  float: left;

  .button-special,
  button {
    width: 100%;
    border-color: #00a8e1;
    color: #00a8e1;
    background-color: #fff;
    margin: 0;

    &:first-child {
      margin-bottom: 5px;
    }

    &:hover {
      background: #00a8e1!important;
      border: 1px solid #00a8e1!important;
      color: #fff!important;
    }
  }
}

.product-comparision__list-group {
  float: left;
  width: calc(100% - 127px);
  padding-left: 82px;
}

.product-comparision__list {
  display: inline-block;
  padding: 0;
  margin: 0 -2px;
  list-style: none;

  &-item--main {
    padding-bottom: 13px;
    border-bottom: 1px solid #DDD;
    padding-right: 49px;
    margin-bottom: 20px;

    a, span {
      display: block;
      width: 100%;
    }
  }

  &-subitem {
    font-size: 12px;
    line-height: 17px;
  }

  a {
    font-size: 14px;
    line-height: 20px;
    color: #387EB9;
    font-weight: 600;
  }
}

.product-comparision__content {
  overflow-x: auto;
}

.product-comparision__table {
  border: 1px solid #dddddd;

  a {
    font-weight: 600;
  }
}

.product-comparision__tbody {

  tr:nth-child(odd) {
    background-color: #F9F9F9;
  }
}

.product-comparision__th:not(.product-comparision__cell--bold) {
  font-weight: 300;
  padding: 7px 21px 3px 21px;
  width: 249px;
}

.product-comparision__select-wrapper {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
  }
}

.product-comparision__select {
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
}

.product-comparision__cell--bold {
  width: 188px;
  padding: 7px 21px 3px 21px;
  border-right: 2px solid #dddddd;
}
