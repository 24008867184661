.table.documents {
  margin-bottom: 24px;
  width: 100%;

  td {
    border-top: none !important;

    &.release-date {
      color: $gray;
      width: 80px;
      @include font-size(13px);
      text-align: right;
      white-space: nowrap;
    }

    a {
      color: $text-black;
      word-break: break-all;

      &.icon, &.download {
        color: $blue;
        border-bottom: none;
        position: relative;

        &:hover, &:focus {
          border-bottom: none !important;
          text-decoration: none !important;
        }

        .lnr {
          @include font-size(20px);
          color: $blue;
          vertical-align: middle;
          position: relative;
        }
      }

      &.icon {
        padding-left: 0;
        padding-right: 3px;
        width: 20px;
        word-break: normal;

        .lnr-file-empty {
          @include font-size(22px);

          &:after {
            content: attr(data-ext);
            font-family: sans-serif;
            position: absolute;
            font-size: 0.5em;
            left: 0.5em;
            background-color: white;
            bottom: 0.22em;
            padding: 0 2px;
          }
        }
      }

      &.download {
        .lnr {
          bottom: 0px;
          transition: all 0.2s ease-in-out 0s;

          &:hover, &:focus {
            bottom: -2px;
          }
        }
      }
    }
  }
}

.document-portfolio-category-tabs {
  &.tabs {
    position: relative;
    z-index: 5;
    margin: 0;
  }
}

.document-portfolio-category {
  &:last-child {
    margin-bottom: 25px;
  }

  .nav-tabs {
    li {
      vertical-align: bottom;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {

  .tab-content {
    .block {
      padding: 24px 15px !important;

    }
  }
  .document-portfolio-category-tabs {
    li {
      width: 100% !important;
    }
  }
}