@mixin transition($args) {
 	-webkit-transition: $args;
 	-moz-transition: $args;
 	-ms-transition: $args;
 	-o-transition: $args;
 	transition: $args;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}


@function calculateRem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

// Horizontal gradient, from left to right
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.

@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.

@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) { 
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
  background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}




//////////////
// ELEMENTS //
//////////////

@mixin thumbnail($height: 172px, $width: 172px, $titleHeight: 60px, $marginBottom: 30px) {
	height: $height; 
	position: relative; 
	border-radius: 0; 
	padding: 0; 
	margin-bottom: $marginBottom; 
	border: 1px solid $gray;
	@extend .font-OpenSans;
	@extend .font-400;
	background-repeat: no-repeat;
	background-size: cover;
		
	a {
		display: table;
		
		.overlay {
			display: table-row;
			width: 100%;
			padding: 10px 45px 10px 15px; 
			position: absolute; 
			bottom: 0; 
			left: 0; 
			background: rgba(255,255,255,0.9);
			@include transition(background 0.3s ease);
			
			.title {
				display: table-cell;
				vertical-align: middle;
				height: $titleHeight;
							
				.lnr { 
					@include font-size(20px);
					text-decoration: none;
					position: absolute;
					right: 20px;
					top: 50%;
					margin-top: -10px;
					color: $blue;
				}
			}
		}
	}
	
	&:hover {
		border: 1px solid $blue;
	
		.overlay {
			background: rgba(0,164,228,0.9);
			color: $text-white;
			
			.title {
				.lnr { 
					color: $text-white;
				}
			}
		}	
	}		
}


@mixin btn-arrow($arrowFontSize: 24px, $iconLocation: right) {
	
	position: relative;
	.lnr {
		@include font-size($arrowFontSize);
		position: absolute;
		top: 50%;
		margin-top: 0-($arrowFontSize / 2);
		@include transition(all .1s ease-in-out);
	}
		
	
	@if $iconLocation == 'right' {
		padding-left: 12px;
		padding-right: $arrowFontSize * 2;
		
		.lnr {
			right: 10px;
		}
		
		&:hover {
			.lnr {
				right: 7px;		
			}
		}
	}
	@else {
		padding-right: 12px;
		padding-left: $arrowFontSize * 2 !important;
				
		.lnr {
			left: 10px;
		}
		
		&:hover {
			.lnr {
				margin-top: 3-($arrowFontSize / 2);
			}
		}
	}
}


@mixin no-select-no-callout() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

