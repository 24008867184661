#disclaimerModal .modal-header { 
	background-color: rgba(0, 59, 113, 0.95); 
	color: #fff;
    font-family: "Open Sans",sans-serif;
    position: relative;
}

#disclaimerModal .modal-body h4 {
    line-height: 18px;
   	padding-bottom: 15px;
 	border-bottom: 1px solid #00a8e1;
}
#disclaimerModal p {
    cell-spacing: 10px;
}

#disclaimerModal ul {
	padding-left: 0px;
	list-style-type: none;
}

#disclaimerModal li{
	padding-left:25px;
	position: relative;
}

#disclaimerModal li::before { 
	color: #00a8e1;
    content: "•";
    font-size: 1.125rem;
    position: absolute;
    line-height: 18px;
    top: 0;
    left: 0;
}