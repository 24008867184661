/* Font Awesome custom, social icons */
@font-face {
	font-family: "social";
	src: url('../fonts/social.eot?43374653');
	src: url('../fonts/social.eot?43374653#iefix') format('embedded-opentype'),
		url('../fonts/social.ttf?43374653') format('truetype'),
		url('../fonts/social.woff?43374653') format('woff'),
	    url('../fonts/social.svg?43374653#social') format('svg');
	font-weight: normal;
	font-style: normal;
}



$iconfontname: "linearicons";

$lnr-cog: "\e810";
$lnr-undo: "\e860";
$lnr-home: "\e600";
$lnr-home2: "\e601";
$lnr-home3: "\e602";
$lnr-home4: "\e603";
$lnr-home5: "\e604";
$lnr-home6: "\e605";
$lnr-color-sampler: "\e627";
$lnr-cloud-upload: "\e64b";
$lnr-cloud-download: "\e64c";
$lnr-lock: "\e66b";
$lnr-trash: "\e680";
$lnr-trash2: "\e681";
$lnr-hdd-down: "\e6ac";
$lnr-hdd-up: "\e6ad";
$lnr-printer: "\e6b1";
$lnr-file-empty: "\e6b3";
$lnr-file-lock: "\e6b6";
$lnr-register: "\e6d1";
$lnr-exit: "\e6d3";
$lnr-document: "\e6d8";
$lnr-document2: "\e6d9";
$lnr-film-play: "\e6f8";
$lnr-camera: "\e6ff";
$lnr-picture: "\e70e";
$lnr-picture2: "\e70f";
$lnr-picture3: "\e710";
$lnr-pictures: "\e711";
$lnr-bookmark2: "\e716";
$lnr-library: "\e718";
$lnr-user: "\e71e";
$lnr-group-work: "\e726";
$lnr-cart: "\e74d";
$lnr-cart-empty: "\e74e";
$lnr-cart-plus: "\e750";
$lnr-cart-plus2: "\e751";
$lnr-cart-add: "\e752";
$lnr-cart-remove: "\e753";
$lnr-cart-exchange: "\e754";
$lnr-tag: "\e755";
$lnr-tags: "\e756";
$lnr-telephone: "\e76a";
$lnr-pushpin2: "\e779";
$lnr-map-marker: "\e77a";
$lnr-location: "\e783";
$lnr-pie-chart: "\e7f8";
$lnr-pie-chart2: "\e7f9";
$lnr-graph: "\e7fa";
$lnr-chart-growth: "\e7fb";
$lnr-chart-bars: "\e7fc";
$lnr-rank: "\e801";
$lnr-rank2: "\e802";
$lnr-rank3: "\e803";
$lnr-cube: "\e874";
$lnr-alarm: "\e8aa";
$lnr-sync: "\e8da";
$lnr-sync2: "\e8dd";
$lnr-download2: "\e8f5";
$lnr-upload2: "\e8f6";
$lnr-enter-up: "\e8f7";
$lnr-enter-down: "\e8f8";
$lnr-enter-left: "\e8f9";
$lnr-enter-right: "\e8fa";
$lnr-exit-up: "\e8fb";
$lnr-exit-down: "\e8fc";
$lnr-exit-left: "\e8fd";
$lnr-exit-right: "\e8fe";
$lnr-file-code: "\e90c";
$lnr-file-image: "\e90d";
$lnr-file-zip: "\e90e";
$lnr-file-audio: "\e90f";
$lnr-file-video: "\e910";
$lnr-file-preview: "\e911";
$lnr-file-charts: "\e912";
$lnr-file-stats: "\e913";
$lnr-file-spreadsheet: "\e914";
$lnr-link2: "\e917";
$lnr-share: "\e91f";
$lnr-share2: "\e920";
$lnr-magnifier: "\e922";
$lnr-cross: "\e92a";
$lnr-menu: "\e92b";
$lnr-list: "\e92c";
$lnr-list2: "\e92d";
$lnr-list3: "\e92e";
$lnr-check: "\e934";
$lnr-cross2: "\e935";
$lnr-plus: "\e936";
$lnr-minus: "\e937";
$lnr-chevron-up: "\e939";
$lnr-chevron-down: "\e93a";
$lnr-chevron-left: "\e93b";
$lnr-chevron-right: "\e93c";
$lnr-chevrons-expand-vertical: "\e93d";
$lnr-chevrons-expand-horizontal: "\e93e";
$lnr-chevrons-contract-vertical: "\e93f";
$lnr-chevrons-contract-horizontal: "\e940";
$lnr-arrow-up: "\e941";
$lnr-arrow-down: "\e942";
$lnr-arrow-left: "\e943";
$lnr-arrow-right: "\e944";
$lnr-arrow-up-right: "\e945";
$lnr-expand: "\e94a";
$lnr-contract: "\e94b";
$lnr-expand2: "\e94c";
$lnr-contract2: "\e94d";
$lnr-expand3: "\e951";
$lnr-expand4: "\e952";
$lnr-contract3: "\e953";
$lnr-warning: "\e955";
$lnr-notification-circle: "\e956";
$lnr-question-circle: "\e957";
$lnr-checkmark-circle: "\e959";
$lnr-cross-circle: "\e95a";
$lnr-plus-circle: "\e95b";
$lnr-circle-minus: "\e95c";
$lnr-arrow-up-circle: "\e95e";
$lnr-arrow-down-circle: "\e95f";
$lnr-arrow-left-circle: "\e960";
$lnr-arrow-right-circle: "\e961";
$lnr-chevron-up-circle: "\e962";
$lnr-chevron-down-circle: "\e963";
$lnr-chevron-left-circle: "\e964";
$lnr-chevron-right-circle: "\e965";
$lnr-play-circle: "\e96a";
$lnr-frame-expand: "\e971";
$lnr-frame-contract: "\e972";
$lnr-focus: "\e973";
$lnr-grid: "\e975";
$lnr-layers: "\e977";
$lnr-funnel: "\e97c";
$lnr-plus-square: "\e98e";
$lnr-minus-square: "\e98f";
$lnr-arrow-up-square: "\e991";
$lnr-arrow-down-square: "\e992";
$lnr-arrow-left-square: "\e993";
$lnr-arrow-right-square: "\e994";
$lnr-chevron-up-square: "\e995";
$lnr-chevron-down-square: "\e996";
$lnr-chevron-left-square: "\e997";
$lnr-chevron-right-square: "\e998";
$lnr-check-square: "\e999";
$lnr-cross-square: "\e99a";
$lnr-menu-square: "\e99b";
$lnr-prohibited: "\e99c";
$lnr-circle: "\e99d";
$lnr-sort-alpha-asc: "\e9b5";
$lnr-sort-alpha-desc: "\e9b6";
$lnr-sort-numeric-asc: "\e9b7";
$lnr-sort-numeric-desc: "\e9b8";
$lnr-sort-amount-asc: "\e9b9";
$lnr-sort-amount-desc: "\e9ba";
$lnr-sort-time-asc: "\e9bb";
$lnr-sort-time-desc: "\e9bc";
$lnr-pencil-line: "\e9be";
$lnr-ellipsis: "\e9e9";

@font-face {
	font-family: $iconfontname;
	src:url('../fonts/linearicons.eot?1yg0hp');
	src:url('../fonts/linearicons.eot?#iefix1yg0hp') format('embedded-opentype'),
		url('../fonts/linearicons.ttf?1yg0hp') format('truetype'),
		url('../fonts/linearicons.woff?1yg0hp') format('woff'),
		url('../fonts/linearicons.svg?1yg0hp#linearicons') format('svg');
	font-weight: normal;
	font-style: normal;
}

$littleHeavierIcons: 600;

[class^="lnr-"], [class*=" lnr-"] {
	font-family: $iconfontname;
	speak: none;
	font-style: normal;
	font-weight: $littleHeavierIcons; //normal
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.lnr-cog {
    &:before {
        content: $lnr-cog;
    }
}
.lnr-undo {
    &:before {
        content: $lnr-undo;
    }
}
.lnr-home {
    &:before {
        content: $lnr-home;
    }
}
.lnr-home2 {
    &:before {
        content: $lnr-home2;
    }
}
.lnr-home3 {
    &:before {
        content: $lnr-home3;
    }
}
.lnr-home4 {
    &:before {
        content: $lnr-home4;
    }
}
.lnr-home5 {
    &:before {
        content: $lnr-home5;
    }
}
.lnr-home6 {
    &:before {
        content: $lnr-home6;
    }
}
.lnr-color-sampler {
    &:before {
        content: $lnr-color-sampler;
    }
}
.lnr-cloud-upload {
    &:before {
        content: $lnr-cloud-upload;
    }
}
.lnr-cloud-download {
    &:before {
        content: $lnr-cloud-download;
    }
}
.lnr-lock {
    &:before {
        content: $lnr-lock;
    }
}
.lnr-trash {
    &:before {
        content: $lnr-trash;
    }
}
.lnr-trash2 {
    &:before {
        content: $lnr-trash2;
    }
}
.lnr-hdd-down {
    &:before {
        content: $lnr-hdd-down;
    }
}
.lnr-hdd-up {
    &:before {
        content: $lnr-hdd-up;
    }
}
.lnr-printer {
    &:before {
        content: $lnr-printer;
    }
}
.lnr-file-empty {
    &:before {
        content: $lnr-file-empty;
    }
}
.lnr-file-lock {
    &:before {
        content: $lnr-file-lock;
    }
}
.lnr-register {
    &:before {
        content: $lnr-register;
    }
}
.lnr-exit {
    &:before {
        content: $lnr-exit;
    }
}
.lnr-document {
    &:before {
        content: $lnr-document;
    }
}
.lnr-document2 {
    &:before {
        content: $lnr-document2;
    }
}
.lnr-film-play {
    &:before {
        content: $lnr-film-play;
    }
}
.lnr-camera {
    &:before {
        content: $lnr-camera;
    }
}
.lnr-picture {
    &:before {
        content: $lnr-picture;
    }
}
.lnr-picture2 {
    &:before {
        content: $lnr-picture2;
    }
}
.lnr-picture3 {
    &:before {
        content: $lnr-picture3;
    }
}
.lnr-pictures {
    &:before {
        content: $lnr-pictures;
    }
}
.lnr-bookmark2 {
    &:before {
        content: $lnr-bookmark2;
    }
}
.lnr-library {
    &:before {
        content: $lnr-library;
    }
}
.lnr-user {
    &:before {
        content: $lnr-user;
    }
}
.lnr-group-work {
    &:before {
        content: $lnr-group-work;
    }
}
.lnr-cart {
    &:before {
        content: $lnr-cart;
    }
}
.lnr-cart-empty {
    &:before {
        content: $lnr-cart-empty;
    }
}
.lnr-cart-plus {
    &:before {
        content: $lnr-cart-plus;
    }
}
.lnr-cart-plus2 {
    &:before {
        content: $lnr-cart-plus2;
    }
}
.lnr-cart-add {
    &:before {
        content: $lnr-cart-add;
    }
}
.lnr-cart-remove {
    &:before {
        content: $lnr-cart-remove;
    }
}
.lnr-cart-exchange {
    &:before {
        content: $lnr-cart-exchange;
    }
}
.lnr-tag {
    &:before {
        content: $lnr-tag;
    }
}
.lnr-tags {
    &:before {
        content: $lnr-tags;
    }
}
.lnr-telephone {
    &:before {
        content: $lnr-telephone;
    }
}
.lnr-pushpin2 {
    &:before {
        content: $lnr-pushpin2;
    }
}
.lnr-map-marker {
    &:before {
        content: $lnr-map-marker;
    }
}
.lnr-location {
    &:before {
        content: $lnr-location;
    }
}
.lnr-pie-chart {
    &:before {
        content: $lnr-pie-chart;
    }
}
.lnr-pie-chart2 {
    &:before {
        content: $lnr-pie-chart2;
    }
}
.lnr-graph {
    &:before {
        content: $lnr-graph;
    }
}
.lnr-chart-growth {
    &:before {
        content: $lnr-chart-growth;
    }
}
.lnr-chart-bars {
    &:before {
        content: $lnr-chart-bars;
    }
}
.lnr-rank {
    &:before {
        content: $lnr-rank;
    }
}
.lnr-rank2 {
    &:before {
        content: $lnr-rank2;
    }
}
.lnr-rank3 {
    &:before {
        content: $lnr-rank3;
    }
}
.lnr-cube {
    &:before {
        content: $lnr-cube;
    }
}
.lnr-alarm {
    &:before {
        content: $lnr-alarm;
    }
}
.lnr-sync {
    &:before {
        content: $lnr-sync;
    }
}
.lnr-sync2 {
    &:before {
        content: $lnr-sync2;
    }
}
.lnr-download2 {
    &:before {
        content: $lnr-download2;
    }
}
.lnr-upload2 {
    &:before {
        content: $lnr-upload2;
    }
}
.lnr-enter-up {
    &:before {
        content: $lnr-enter-up;
    }
}
.lnr-enter-down {
    &:before {
        content: $lnr-enter-down;
    }
}
.lnr-enter-left {
    &:before {
        content: $lnr-enter-left;
    }
}
.lnr-enter-right {
    &:before {
        content: $lnr-enter-right;
    }
}
.lnr-exit-up {
    &:before {
        content: $lnr-exit-up;
    }
}
.lnr-exit-down {
    &:before {
        content: $lnr-exit-down;
    }
}
.lnr-exit-left {
    &:before {
        content: $lnr-exit-left;
    }
}
.lnr-exit-right {
    &:before {
        content: $lnr-exit-right;
    }
}
.lnr-file-code {
    &:before {
        content: $lnr-file-code;
    }
}
.lnr-file-image {
    &:before {
        content: $lnr-file-image;
    }
}
.lnr-file-zip {
    &:before {
        content: $lnr-file-zip;
    }
}
.lnr-file-audio {
    &:before {
        content: $lnr-file-audio;
    }
}
.lnr-file-video {
    &:before {
        content: $lnr-file-video;
    }
}
.lnr-file-preview {
    &:before {
        content: $lnr-file-preview;
    }
}
.lnr-file-charts {
    &:before {
        content: $lnr-file-charts;
    }
}
.lnr-file-stats {
    &:before {
        content: $lnr-file-stats;
    }
}
.lnr-file-spreadsheet {
    &:before {
        content: $lnr-file-spreadsheet;
    }
}
.lnr-link2 {
    &:before {
        content: $lnr-link2;
    }
}
.lnr-share {
    &:before {
        content: $lnr-share;
    }
}
.lnr-share2 {
    &:before {
        content: $lnr-share2;
    }
}
.lnr-magnifier {
    &:before {
        content: $lnr-magnifier;
    }
}
.lnr-cross {
    &:before {
        content: $lnr-cross;
    }
}
.lnr-menu {
    &:before {
        content: $lnr-menu;
    }
}
.lnr-list {
    &:before {
        content: $lnr-list;
    }
}
.lnr-list2 {
    &:before {
        content: $lnr-list2;
    }
}
.lnr-list3 {
    &:before {
        content: $lnr-list3;
    }
}
.lnr-check {
    &:before {
        content: $lnr-check;
    }
}
.lnr-cross2 {
    &:before {
        content: $lnr-cross2;
    }
}
.lnr-plus {
    &:before {
        content: $lnr-plus;
    }
}
.lnr-minus {
    &:before {
        content: $lnr-minus;
    }
}
.lnr-chevron-up {
    &:before {
        content: $lnr-chevron-up;
    }
}
.lnr-chevron-down {
    &:before {
        content: $lnr-chevron-down;
    }
}
.lnr-chevron-left {
    &:before {
        content: $lnr-chevron-left;
    }
}
.lnr-chevron-right {
    &:before {
        content: $lnr-chevron-right;
    }
}
.lnr-chevrons-expand-vertical {
    &:before {
        content: $lnr-chevrons-expand-vertical;
    }
}
.lnr-chevrons-expand-horizontal {
    &:before {
        content: $lnr-chevrons-expand-horizontal;
    }
}
.lnr-chevrons-contract-vertical {
    &:before {
        content: $lnr-chevrons-contract-vertical;
    }
}
.lnr-chevrons-contract-horizontal {
    &:before {
        content: $lnr-chevrons-contract-horizontal;
    }
}
.lnr-arrow-up {
    &:before {
        content: $lnr-arrow-up;
    }
}
.lnr-arrow-down {
    &:before {
        content: $lnr-arrow-down;
    }
}
.lnr-arrow-left {
    &:before {
        content: $lnr-arrow-left;
    }
}
.lnr-arrow-right {
    &:before {
        content: $lnr-arrow-right;
    }
}
.lnr-arrow-up-right {
    &:before {
        content: $lnr-arrow-up-right;
    }
}
.lnr-expand {
    &:before {
        content: $lnr-expand;
    }
}
.lnr-contract {
    &:before {
        content: $lnr-contract;
    }
}
.lnr-expand2 {
    &:before {
        content: $lnr-expand2;
    }
}
.lnr-contract2 {
    &:before {
        content: $lnr-contract2;
    }
}
.lnr-expand3 {
    &:before {
        content: $lnr-expand3;
    }
}
.lnr-expand4 {
    &:before {
        content: $lnr-expand4;
    }
}
.lnr-contract3 {
    &:before {
        content: $lnr-contract3;
    }
}
.lnr-warning {
    &:before {
        content: $lnr-warning;
    }
}
.lnr-notification-circle {
    &:before {
        content: $lnr-notification-circle;
    }
}
.lnr-question-circle {
    &:before {
        content: $lnr-question-circle;
    }
}
.lnr-checkmark-circle {
    &:before {
        content: $lnr-checkmark-circle;
    }
}
.lnr-cross-circle {
    &:before {
        content: $lnr-cross-circle;
    }
}
.lnr-plus-circle {
    &:before {
        content: $lnr-plus-circle;
    }
}
.lnr-circle-minus {
    &:before {
        content: $lnr-circle-minus;
    }
}
.lnr-arrow-up-circle {
    &:before {
        content: $lnr-arrow-up-circle;
    }
}
.lnr-arrow-down-circle {
    &:before {
        content: $lnr-arrow-down-circle;
    }
}
.lnr-arrow-left-circle {
    &:before {
        content: $lnr-arrow-left-circle;
    }
}
.lnr-arrow-right-circle {
    &:before {
        content: $lnr-arrow-right-circle;
    }
}
.lnr-chevron-up-circle {
    &:before {
        content: $lnr-chevron-up-circle;
    }
}
.lnr-chevron-down-circle {
    &:before {
        content: $lnr-chevron-down-circle;
    }
}
.lnr-chevron-left-circle {
    &:before {
        content: $lnr-chevron-left-circle;
    }
}
.lnr-chevron-right-circle {
    &:before {
        content: $lnr-chevron-right-circle;
    }
}
.lnr-play-circle {
    &:before {
        content: $lnr-play-circle;
    }
}
.lnr-frame-expand {
    &:before {
        content: $lnr-frame-expand;
    }
}
.lnr-frame-contract {
    &:before {
        content: $lnr-frame-contract;
    }
}
.lnr-focus {
    &:before {
        content: $lnr-focus;
    }
}
.lnr-grid {
    &:before {
        content: $lnr-grid;
    }
}
.lnr-layers {
    &:before {
        content: $lnr-layers;
    }
}
.lnr-funnel {
    &:before {
        content: $lnr-funnel;
    }
}
.lnr-plus-square {
    &:before {
        content: $lnr-plus-square;
    }
}
.lnr-minus-square {
    &:before {
        content: $lnr-minus-square;
    }
}
.lnr-arrow-up-square {
    &:before {
        content: $lnr-arrow-up-square;
    }
}
.lnr-arrow-down-square {
    &:before {
        content: $lnr-arrow-down-square;
    }
}
.lnr-arrow-left-square {
    &:before {
        content: $lnr-arrow-left-square;
    }
}
.lnr-arrow-right-square {
    &:before {
        content: $lnr-arrow-right-square;
    }
}
.lnr-chevron-up-square {
    &:before {
        content: $lnr-chevron-up-square;
    }
}
.lnr-chevron-down-square {
    &:before {
        content: $lnr-chevron-down-square;
    }
}
.lnr-chevron-left-square {
    &:before {
        content: $lnr-chevron-left-square;
    }
}
.lnr-chevron-right-square {
    &:before {
        content: $lnr-chevron-right-square;
    }
}
.lnr-check-square {
    &:before {
        content: $lnr-check-square;
    }
}
.lnr-cross-square {
    &:before {
        content: $lnr-cross-square;
    }
}
.lnr-menu-square {
    &:before {
        content: $lnr-menu-square;
    }
}
.lnr-prohibited {
    &:before {
        content: $lnr-prohibited;
    }
}
.lnr-circle {
    &:before {
        content: $lnr-circle;
    }
}
.lnr-sort-alpha-asc {
    &:before {
        content: $lnr-sort-alpha-asc;
    }
}
.lnr-sort-alpha-desc {
    &:before {
        content: $lnr-sort-alpha-desc;
    }
}
.lnr-sort-numeric-asc {
    &:before {
        content: $lnr-sort-numeric-asc;
    }
}
.lnr-sort-numeric-desc {
    &:before {
        content: $lnr-sort-numeric-desc;
    }
}
.lnr-sort-amount-asc {
    &:before {
        content: $lnr-sort-amount-asc;
    }
}
.lnr-sort-amount-desc {
    &:before {
        content: $lnr-sort-amount-desc;
    }
}
.lnr-sort-time-asc {
    &:before {
        content: $lnr-sort-time-asc;
    }
}
.lnr-sort-time-desc {
    &:before {
        content: $lnr-sort-time-desc;
    }
}
.lnr-pencil-line {
    &:before {
        content: $lnr-pencil-line;
    }
}
.lnr-ellipsis {
    &:before {
        content: $lnr-ellipsis;
    }
}


/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'social';
    src: url('../resources/fonts/social.svg?43374653#social') format('svg');
  }
}
*/

.share {
	[class^="icon-"]:before, [class*=" icon-"]:before {
		font-family: "social";
		font-style: normal;
		font-weight: normal;
		speak: none;
		
		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		margin-right: .2em;
		text-align: center;
		/* opacity: .8; */
		
		/* For safety - reset parent styles, that can break glyph codes*/
		font-variant: normal;
		text-transform: none;
		
		/* fix buttons height, for twitter bootstrap */
		line-height: 1em;
		
		/* Animation center compensation - margins should be symmetric */
		/* remove if not needed */
		margin-left: .2em;
		
		/* you can be more comfortable with increased icons size */
		/* font-size: 120%; */
		
		/* Font smoothing. That was taken from TWBS */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		
		/* Uncomment for 3D effect */
		/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
	}
}
 
.icon-facebook-squared:before { content: '\e800'; } /* '' */
.icon-linkedin-squared:before { content: '\e801'; } /* '' */
.icon-twitter-squared:before { content: '\e802'; } /* '' */
.icon-facebook:before { content: '\e803'; } /* '' */
.icon-linkedin:before { content: '\e804'; } /* '' */
.icon-twitter:before { content: '\e805'; } /* '' */
.icon-gplus-squared:before { content: '\e806'; } /* '' */
.icon-gplus:before { content: '\e807'; } /* '' */
