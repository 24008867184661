// My Ampleon pages

.myAmpleon {

  .row__registration-successful {
    .image-section {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}