.text-image-section {
	float: left;
	padding: $componentPadding;
	
	h1, h2 {
		color: $text-black; 
		@include font-size(16px);
		@extend .font-600;  
		margin-top: 0;
		padding-top: 0;
	}
	
	img {
		&.content-image-above, &.content-image-below {
			width: 100%;
			max-width: 100%;
		}
		
		&.content-image-above {
			margin: 0 0 15px 0;
		}
		
		&.content-image-below {
			margin: 15px 0 0 0;
		}
		
		&.content-image-left {
			float: left;
			margin: 0 15px 15px 0;
			max-width: 200px !important;
		}
		
		&.content-image-right {
			float: right;
			margin: 0 0 15px 15px;
			max-width: 200px !important;
		}
	}
 }
 
 .text-section + .text-section {
 	padding-top: 0;
 }
 
 .text-section {
 	padding: $componentPadding;
 }

.row.is-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.row.is-flex > [class*='col-'] {
	padding-top: 24px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}
 
 
 @media screen and (max-width: $screen-sm-max) {
 	.text-section {
 		margin-top: 0;
 	}
 	
 	.text-section + .text-section {
 		padding-top: 0;
 	}
 }
 
 @media screen and (max-width: $screen-xs-max) {
 	.text-section {
 		img {
 			&.content-image-left {
				max-width: 150px;
			}
			
			&.content-image-right {
				max-width: 150px;
			}
 		}
 	}	
 	
 	.text-section + .text-section {
 		padding-top: 24px;
 	}
 } 