.main-content-wrapper {
  position: relative;
  z-index: 5;

  .search-box {
    margin: -154px auto 0;
    z-index: 9999;
    background-color: #fff;
    width: 617px;
    padding: 13px 47px;
    border-bottom: 1px solid #fff;
    padding-bottom: 31px;
    transition: all 0.2s;

    .nav {
      border-bottom: none;
      display: flex ;
      justify-content: space-between;
      flex-wrap: wrap;

      .nav-item {
        margin-bottom: 1px;
        margin-top: 2px;
        transition: all 0.2s;

        a {
          transition: all 0.2s;
          border: none !important;

          padding: 3px 3px!important;
          color: #333333;
          @extend .font-600;
          font-size: 0.99rem;
          text-transform: uppercase;
          text-shadow: 1px 0 #333333;
          font-weight: 700;
          outline: none;

          &:hover {
            background-color: #fff;
            color: $blue;
            text-shadow: 1px 0 $blue;
          }

          &:focus {
            background-color: #fff;
          }

          &:active {
            background-color: #fff;
          }
        }

        &.active {
          a {
            color: $blue;
            text-shadow: 1px 0 $blue;
            font-weight: 700;
          }
        }
      }
    }

    .tab-content {
      padding: 0 !important;
      #tabPanelRegularSearch {
        .navbar-form {
          padding-left: 0;
          padding-right: 0;
          margin-top: 13px;

          .form-group {
            width: 100%;
            display: block;

            #nav-search {
              width: 100%;
              padding: 21px 25px 23px 46px;
              font-size: 15px;
              font-weight: bold;
              border: 1px solid #f1f1f1;
              box-shadow: none;
              transition: all 0.2s;
              height: 57px;

              &:focus {
                border-color: $bluelightest !important;
                box-shadow: 0 0px 4px rgba(0, 168, 225, 0.2) !important;
              }
            }

            .submit-btn {
              position: absolute;
              right: 0;
              top: 0;
              display: none;
            }

            .expand-search {
              position: absolute;
              top: 20px;
              left: 19px;
              cursor: pointer;
              font-weight: bold;
              color: $blue;
            }
          }
        }
      }

      #tabPanelParametricSearch {

        .simplebar-scrollbar {
          opacity: 1 !important;
        }

        ul {
          padding: 0 16px;

          ul {
            border-bottom: none;
            padding-right: 0;
            padding-left: 30px;

            li {
              padding: 0;
              a {
                color: $darkergray;
                display: block;
                padding: 8px 0;
                transition: all 0.2s;
                @include font-size(13px);

                &:hover {
                  color: $blue;
                }
              }
            }
          }

          li {
            padding: 15px 0;
            list-style: none;
            border-bottom: 1px solid $lightgray;

            &:last-child {
              border-bottom: none;
            }

            a {
              color: $text-black;
              font-weight: bolder;
              text-decoration: none;
              display: block;
              transition: all 0.2s;

              &:hover {
                color: $blue;
              }

              i {
                float: right;
                @extend .font-300;
                @include font-size(12px);
              }
            }
          }
        }
      }
    }
  }
}

.intro {
  .intro-title {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-content-wrapper {
    .search-box {
      margin: -80px auto 0;
      padding-left: 15px;
      padding-right: 15px;

      .nav-item {
        display: block;
        width: 100%;
        text-align: center;

        a {
          margin: 0 !important;
        }
      }

      .nav-item:nth-child(1) a {
        padding-right: 0px !important;
      }

      .navbar-form {
        margin-top: 0;
        padding-top: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 645px) {
  .main-content-wrapper {
    .search-box {
      width: auto;
    }
  }
}


.simplebar, [data-simplebar-direction] {
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch; /* Trigger native scrolling for mobile, if not supported, plugin is used. */
}

.simplebar .simplebar-scroll-content,
[data-simplebar-direction] .simplebar-scroll-content {
  overflow-y: scroll;
  overflow-x: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -ms-overflow-style: none; /* hide browser scrollbar on IE10+ */
}

/* hide browser scrollbar on Webkit (Safari & Chrome) */
.simplebar-scroll-content::-webkit-scrollbar {
  display: none;
}

[data-simplebar-direction="horizontal"] .simplebar-scroll-content,
.simplebar.horizontal .simplebar-scroll-content {
  overflow-x: scroll;
  overflow-y: auto;
}

.simplebar-track {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 11px;
}

.simplebar-track .simplebar-scrollbar {
  position: absolute;
  right: 2px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  min-height: 10px;
  width: 7px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  background: #6c6e71;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
}

.simplebar-track:hover .simplebar-scrollbar {
  /* When hovered, remove all transitions from drag handle */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  opacity: 0.7;
  -webkit-transition: opacity 0 linear;
  -moz-transition: opacity 0 linear;
  -o-transition: opacity 0 linear;
  -ms-transition: opacity 0 linear;
  transition: opacity 0 linear;
}

.simplebar-track .simplebar-scrollbar.visible {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  opacity: 0.7;
}

[data-simplebar-direction="horizontal"] .simplebar-track,
.simplebar.horizontal .simplebar-track {
  top: auto;
  left: 0;
  width: auto;
  height: 11px;
}

[data-simplebar-direction="horizontal"] .simplebar-track .simplebar-scrollbar,
.simplebar.horizontal .simplebar-track .simplebar-scrollbar {
  right: auto;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}