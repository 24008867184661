////////////
// COLORS //
////////////

// Components
	$dark:					#333;
	$darkblue: 				#003b71;
	$lighterblue:			#57a1d5;
	$darkgray: 				#777;
	$darkergray:			#ACACAC;
	$gray: 					#d8d9db;
	$lightgray: 			#e7e7e8;
	$lightestgray:			#f1f1f2;
	$almostwhite:			#f7f7f7;

	//Bright blue
	$blue: 					#00a8e1;
	$bluelighter:			#4cc8e9;
	$bluelightest:			#ace0f0;

// Typography
	$text-dark:		#000000;
	$text-black: 	#222222;
    $text-gray:     #333333;
	$text-blue:		#00a4e4;
	$text-white:	#ffffff;

// Classes
	.darkblue 		{	color: $darkblue;	}
	.blue 			{	color: $blue;		}
	.lightgray 		{	color: $lightgray;	}
	.gray 			{	color: $gray;		}
	.black 			{	color: $text-black;	}
	.white 			{	color: $text-white;	}
	.bluelightest	{	color: $bluelightest; }

///////////
// PLACEHOLDERS //
///////////

%video-header-adjustments {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

%image-header-background {
	height: auto;
	width: 112%;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
}

%banner-gradient-bg {
	@extend .gradient-diagonal;
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 3;
}

%category-item-title {
	color: $darkergray;
	text-decoration: none;
	transition: all 0.2s;
	@extend .font-600;
	@include font-size(13px);
}

%category-active {
	background-color: transparent;
	color: $text-black;
	outline: none;
}

%category-item-url {
	@extend .font-600;
	text-decoration: none;
	transition: all .2s;
}

%sitemap-link {
	@extend .font-600;
	@include font-size(13px);
	text-decoration: none;
	transition: all .2s;
	color: $gray-dark;
}

///////////
// FONTS //
///////////

$brand-font-family: 'Open Sans', sans-serif;

body {
	@extend .font-OpenSans;
	//font-family: Arial, sans-serif;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: 400;
}

.font-OpenSans {
	font-family: 'Open Sans', Arial, sans-serif;
}

.font-OpenSansLight {
	font-family: 'Open Sans', Arial, sans-serif;
	@extend .font-300;
}

.font-OpenSansSemiBold {
	font-family: 'Open Sans', Arial, sans-serif;
	@extend .font-600;
}

.font-OpenSansMedium {
	font-family: 'Open Sans', Arial, sans-serif;
	@extend .font-600;
}

.font-300 {	font-weight: 300; }
.font-400 {	font-weight: 400; }
.font-500 {	font-weight: 500; }
.font-600 {	font-weight: 600; }

.lnr {
	@extend .font-300;
}


//////////////
// HEADINGS //
//////////////

h1, .h1 {
	.small-text{
		padding-top: 10px;
	}
}

h2, .h2 {
	@extend .font-300;
	@include font-size(24px);
}

h3, .h3 {
	margin: 0 0 30px 0;
	padding-bottom: 15px;
	border-bottom: 1px solid $blue;
	display: block;
	@extend .font-300;

	a.view-all {
		@include font-size(14px);
		@extend .font-OpenSans;
		@extend .font-300;
		color: $text-black;
		text-decoration: none !important;
		border: none !important;
		position: relative;

		.lnr {
			@include font-size(20px);
			text-decoration: none;
			color: $blue;
			position: relative;
			top: 3px;
			padding-left: 5px;
		}

		&:hover {
			color: $blue;
		}
	}
	line-height: 18px;
}

@media screen and (max-width: $screen-xs-max) { 
	h3, .h3 {
		line-height: 24px;
		display: inline-block;
		width: 100%;
		
		a.view-all {
			display: inline-block;
		}
	}
}


///////////////
// 5 COLUMNS //
///////////////

	.row.row-5ths {
		margin-left: -10px !important;
		margin-right: -10px !important;
		
		.col-xs-5ths,
		.col-sm-5ths,
		.col-md-5ths,
		.col-lg-5ths {
		    min-height: 1px;
		    padding-left: 10px;
		    padding-right: 10px;
		    position: relative;
		}
		
		.col-xs-5ths {
		    width: 20%;
		    float: left;
		}
		
		@media (min-width: 768px) {
		.col-sm-5ths {
		        width: 20%;
		        float: left;
		    }
		}
		
		@media (min-width: 992px) {
		    .col-md-5ths {
		        width: 20%;
		        float: left;
		    }
		}
		
		@media (min-width: 1200px) {
		    .col-lg-5ths {
		        width: 20%;
		        float: left;
		    }
		}
	}



///////////////
// GRADIENTS //
///////////////

	.gradient-vertical-black {
		@include gradient-vertical(rgba(0,0,0,0), rgba(0,0,0,1), 0%, 100%);
	}

	.gradient-diagonal {
		background-image: linear-gradient( to top right, rgba(black,0.75), rgba(black,0) );
	}

	.gradient-brand {
		@extend .gradient-diagonal;
	}
	
	
//////////
// MISC //
//////////

.h-inactive {
	display: none !important;
}

.custom-checkbox-wrapper {
	float: left;
	height: 100%;
	margin: -9px 37px 0 0;
	width: 12px;
}

.custom-checkbox__input {
	position: absolute;
	top: 50%;
	float: left;
	width: 12px;
	margin: 0;
	opacity: 0;
	transform: translateY(-50%);
	cursor: pointer;

	&:checked + .custom-checkbox__checkmark {
		background-image: url('../images/checkmark-blue.png');
		background-size: 9px;
		background-position-y: -1px;
		background-repeat: no-repeat;
	}
}

.custom-checkbox__checkmark {
	height: 12px;
	width: 12px;
	border: 1px solid #dddddd;
	background: transparent;
	pointer-events: none;
	cursor: pointer;
	display: block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	&:after {
		content: '';
		position: absolute;
		height: 12px;
		width: 12px;
	}
}

.tab-overflow {
	max-height: 350px !important;
	overflow-y: scroll !important;

	&::-webkit-scrollbar {
		width: 5px !important;
		height: 8px !important;
		border-radius: 5px !important;
		background-color: $lightestgray !important;
	}

	&::-webkit-scrollbar-thumb {
		background: $darkgray !important;
		border-radius: 7px !important;
	}
}

.row.no-margin {
	margin: 0
}

.mobile-main-nav {
	margin-top: 50px;
	transition: all 0.2s;
	display: block !important;
}

.my-admin {
	table.tablesorter {
		thead {
			tr {
				th {
					vertical-align: middle !important;
				}
			}
		}
	}
}

table.tablesorter {
	thead {
		tr {
			th {
				background-color: transparent;
			
				&.header {
					vertical-align: bottom;
					background: url(/.resources/ampleon-theme/img/bg.gif) center right no-repeat;
					@extend .font-600;
				}
				&.headerSortUp {
				    background: url(/.resources/ampleon-theme/img/asc.gif) center right no-repeat; 
				}
				&.headerSortDown {
				    background: url(/.resources/ampleon-theme/img/desc.gif) center right no-repeat; 
				} 
			}	
		}
	}
	
	tbody {
		tr {
			td {
				a.download {
					text-decoration: none !important;
				}	
			}
		}
	}
}

p.red, span.red, a.red {
	color: red;
}

a.red:hover {
	color: red !important;
}

.intro {
	margin-top: -44px;
	margin-bottom: 44px;
	
	.intro-title {
		color: $text-white;
		@include font-size(24px);
		margin: 0 0 50px 0;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
	}
}

.bootstrap-select {
	.dropdown-toggle:hover, .dropdown-toggle:active, .dropdown-toggle:focus {
		outline: 0 !important;
	}

	.dropdown-menu.open {
		.dropdown-menu.inner > li {
			&>a:hover, &>a:active, &>a:focus {
				outline: 0 !important;
			}
		}
	}
}


.table.table-striped.ampleon {
	> thead {
		> tr {
			background: #7fd3f0;
			color: #ffffff;
		}
	}

	> tbody {
		> tr {
			background: #e5f6fc;
		} 
		> tr:nth-of-type(2n+1) {
    		background-color: #cceef9;
		}
	}
	
	> tbody, > thead {
		> tr td, > tr th {
			border: 1px solid #ffffff;
		}
	}	
}

.small-text {
	font-size: 0.875rem;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
	a:hover, a:focus {
		color: $bluelightest;
		text-decoration: underline;
	}
}

*[data-toggle="tooltip"] {
	cursor: help;
	span {
		border-bottom: 1px dotted $blue;
	}
}

table.table {
	th {
		padding-right: 20px !important;
	}
}

.tooltip {
  &.in     { @include opacity(0.9); }
}
.tooltip-inner {
  background-color: $blue;
}



.banner {
	z-index: 1;
	
	.banner-gradient {
		z-index: 2;
	}
	
	.page-title {
		position: absolute;
		bottom: -10px;
		padding: 15px 15px 15px 0;
		margin-left: 25%;
		z-index: 3;
		color: $text-white;
		@include font-size(24px);
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
	}

	.category-data {
		z-index: 5;
		position: absolute;
		bottom: 10px;
		width: 100%;
						
		.page-title {
			position: absolute;
			bottom: 0px;
			padding: 15px 15px 15px 0;
			margin-left: 25%;
			z-index: 3;
			color: $text-white; 
			@include font-size(24px);
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		}
				
		.category-download-parametrics {
			color: #ace0f0;
			@include font-size(14px);
			position: relative;
			padding-right: 24px;
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
			display: inline-block;
			float: left;
			
			.lnr {
				@include font-size(20px);
				@extend .font-300;
				//position: absolute;
				text-decoration: none;
				border: none;
				margin-left: 3px;
				right: 0;
				bottom: 0;
			}
		}
		
		&.visible-xs {
			left: 0;
			padding: 0 15px;
						
			.category-download-parametrics {
				text-shadow: none;
				float: none;
				width: 100%;
				margin-left: 0 !important;
			}
		}
	}
}

.expanded-section-nav {
	transition: all 0.4s !important;
	max-height: 500px !important;
}

.expanded-section-icon {
	transform: rotate(180deg);
}

.visible-nav-items {
	opacity: 1 !important;
	transition: all 0.5s;
	margin-left: 0px !important;
}

.expanded-description-details {
	height: auto !important;
}

.expanded-description-cross {
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
	transition: all 0.2s;
}

.chemicalContentDetail, .searchResult, .newsCategory {
	.banner {
		.page-title {
			margin-left: 0;
		}
	}
}

.productDetail {
	.banner {
		.page-title {
			margin-left: 16.6667%;
		}
	}
}

.content {
	margin-top: 3px;

	.productDetail {
		.xs-pull {
			margin-top: 20px;
		}
	}
}

@media screen and (min-width: $screen-xs-max) and (max-width: $screen-sm-max) {
	.banner {
		.page-title, .category-download-parametrics {
			margin-left: 0 !important;
		}

		.category-data {
			max-width: 90%;
		}
	}
}

@media screen and (max-width: $screen-xs-max) {
	.content {
		margin-top: 20px;
	}

	.chemicalContentDetail, .searchResult, .newsCategory, .productOverview, .section, .applicationOverview, .applicationCategory, .application, .newsOverview, .newCategory, .newsItem, .mediaDownload, .packageOverview {
		.banner {
			.page-title, .category-download-parametrics {
				margin-left: 0 !important;
			}
		}
	}
}

.component-wrapper {
	position: relative;
	z-index: 1;
	width: 1170px;
	margin: 0 auto;
	height: auto;
	overflow: auto;
	margin-right: auto;
	margin-left: auto;
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s;
	padding-left: 15px;
	padding-right: 15px;
}

@media only screen and (max-width: 1200px) {
  .component-wrapper {
	  width: 970px;
	  margin: 0 auto;
	  height: auto;
	  overflow: auto;
	  -webkit-transition: all .2s;
	  -o-transition: all .2s;
	  transition: all .2s;
  }
}

@media only screen and (max-width: 992px) {
  .component-wrapper {
	  padding-left: 15px;
	  padding-right: 15px;
	  width: 750px;
  }
}

@media only screen and (max-width: 768px) {
  .component-wrapper {
    width: auto !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}

// checkbox group

.product-detail__checkbox-group {
	padding: 0;
	margin: 0;
}

.product-detail__btn {
	border: 1px solid #00a8e1;
	vertical-align: inherit;
	line-height: 1.4;
	transition: background .2s;
	color: #00a8e1;
	margin-right: 5px;

	&:hover,
	&:active,
	&:focus {
		color: #ffffff;
		border: 1px solid #00a8e1;
		background-color: #00a8e1 !important;
	}
}

// dropdown

.product-detail__dropdown {
	list-style: none;
	vertical-align: top;

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		list-style: none;
		width: auto;
	}

	&-anchor {
		font-size: 14px;
		color: #00a8e1;
		border: 1px solid #00a8e1;
		text-decoration: none !important;
		margin-right: 5px;
		transition: background .2s;
		line-height: 1.4;

		&:hover,
		&:active,
		&:focus,
		&:active:focus {
			color: #ffffff;
			border: 1px solid #00a8e1;
			background-color: #00a8e1 !important;
		}

    i {
			transform: rotate(45deg) translate(1px,-3px);
      font-size: 12px;
      transform-origin: center;
      margin-left: 5px;
			transition: transform 0.2s;
    }

		i.dropdown-icon--active {
			transform: rotate(90deg) translate(-1px, -3px);
		}
	}

	&-menu {
		position: relative;
		box-shadow: none;
		margin: 0;
		padding-top: 17px;
		border: none;
		display: none;

		&--active {
			display: block;
		}
	}

	&-menu__item {
		position: relative;
		list-style: none;
		margin-bottom: 15px;
	}

	&-menu__item--bold {

		label {
			font-weight: 600 !important;
		}
	}

	label {
		margin: 0 0 0 18px;
		font-size: 14px;
		line-height: 13px;
		font-weight: normal;
	}

	&-menu-col {
		display: inline-block;
	}
}

.myAmpleon .sidebar,
.myAmpleon .banner {
	display: none;
}

.myAmpleon .main > .container {
	width: auto;
}

// There is inherited content class that we don't need on the myAmpleon pages
.myAmpleon .content {
	display: none;
}

//bootstrap safari grid issue
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	.row:before {
		width: 0;
	}
}