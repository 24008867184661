#individualTableHero {
  border-bottom: 1px solid #e7e7e8;
}

#individualTableHero .container {
  position: relative;
  display: flex;
  height: fit-content;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
}
#individualTableHero .inner-container {
  z-index: 2;
}

#individualTableHero .tagline {
  font-size: 1.3rem !important;
  color: #333333 !important;
  margin-bottom: 0;
}

#individualTableHero .title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: start;
  color: #333333;
}

#individualTableHero .description {
  color: #888;
  font-size: 1rem;
  margin-bottom: 40px;
}

#individualTableHero .hero-button {
  color: #00a8e1;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  font-size: 1.2rem;
  text-decoration: none;
}

#individualTableHero .hero-button:hover {
  color: #4cc8e9;
  text-decoration: none;
}

#individualTableHero .image {
  height: 240px;
  width: auto;
  position: absolute;
  left: 50%;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 768px) {

  #individualTableHero .container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;
  }

  #individualTableHero .inner-container {
    width: 100%;
    flex: none;
    order: 1;
  }
  #individualTableHero .tagline {
    font-size: 1rem;
  }

  #individualTableHero .image {
    display: none;
    position: static;
  }
}