.site-footer {
	color: #777;
	font-size: 13px;
	background-color: #f8f8f8;
	border-top: 1px solid #eaeaea;

	.col-xs-12 {
		padding: 0;
	}

	h3 {
		display: block;
		padding-bottom: 10px;
		margin-bottom: 15px;
		border-bottom: 1px solid #ccc;
		@include font-size(18px);
	}

	.footer-news {
		.media {
			.media-left {
				padding-right: 20px;
			}

			.media-body {

				.media-heading {
					margin-bottom: 10px;
					@include font-size(16px);

					a {
						color: #777;
					}
				}
			}
		}
	}

	.social {
		background: #eee;
		padding: $componentPadding;

		.footer-social {
			.fa {
				font-size: 4.5em;
				margin: 0 15px 10px 0;
			}
		}
	}

	.copyright {
		background: $darkblue;
		padding: 20px 0;
		@extend .font-OpenSans;
		@extend .font-400;
		line-height: 100%;
		color: $text-white;

		.row {
			margin-left: -10px;
			margin-right: -15px;

			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;

				li {
					float: left;
					line-height: 100%;

					&:first-child a,
					&:first-child span {
						border-left: 0;
					}

					a,
					span {
						transition: all 0.2s;
						color: $text-white;
						line-height: 100%;
						padding: 0 10px;
						border-left: 1px solid $gray;
					}

					a:hover {
						text-decoration: none;
						color: $blue;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $screen-xs-max) {
	.site-footer {
		.social {
			padding: 0;

			.col-xs-12 {
				margin: 15px 0;
			}
		}
	
		.col-xs-12 {
			text-align: left;
			
			.media {
				.media-left, .media-body {
					width: 100%;
					display: block;
					text-align: left;
					padding-right: 0;
					
					.media-object {
						display: inline;
						margin-bottom: 15px;	
					}
				}
			}
		}
		
		.copyright {
			.col-xs-12 {
				text-align: center;
			}
			
			ul {
				float: left !important;
				width: 100%;
				margin: 20px 0 0 0;
				padding: 0;
				
				li {
					width: 100%;
					text-align: center;
					
					a {
						color: white;
						padding: 15px 0;
						transition: all .1s linear;
						display: block;
						border: none !important;
						text-decoration: none !important;
										
						&:hover, &:active, &:focus {
							color: $blue;
						}
					}
					
					&:last-child {
						a {
							border-bottom: none !important;
						}
					}
				}
			}	
		}
	}
}

@media screen and (max-width: $screen-sm-max) {
	.site-footer {
		.social {
			padding: 24px 0;

			.col-sm-12, .col-sm-6, .col-md-6 {
				margin: 0 0 15px 0;
			}
		}

		.copyright {
			.row {
				ul {
					li {
						text-align: left;
						margin: 12px 0px;

						a {
							display: inline-block;
						}

						span {
							border: none;
						}
					}
				}
			}
		}
	}
}


html, body {
	height: 100%;
}
body {
	display: table;
	width: 100%;
	table-layout: fixed;
}
#wrap {
	display: table-row;
    height: auto;
}

@media only screen and (min-width : 1200px) {
	.myAmpleon {

		.site-footer .container {
			margin: 0 60px;
			width: auto;
		}

		.subfooter-content-wrapper .site-map-wrapper,
		.newsletter-subscription-wrapper,
		.social-toolbar-wrapper .col-md-6 {
			max-width: 585px;
		}

		.social-toolbar-wrapper .col-md-6 {
			padding-left: 15px;
		}
	}
}