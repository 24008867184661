$globalPadding: 20px;
.sidebar {
    .sub-nav {

        font-family: $brand-font-family;
        .h3, h3 {
            background-color: $blue;
            padding: $globalPadding;
            color: white;
            margin-bottom: 0;
            border-bottom: none;
        }

        margin-top: -58px;
        position: relative;
        z-index: 4;
        
        @media screen and (max-width: $screen-xs-max) {
            margin-top: 0;
        }
        margin-bottom: $globalPadding * 2;
        background-color: white;

        &.parsed ul {
            transition: max-height .3s ease-in-out;
        }

        ul .count {
            color: $gray;
        }
        ul ul .count {
            color: $gray;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            //border: 1px solid $lightgray;
            overflow: hidden;

            ul.closed {
                max-height: 0px !important;
            }

            a {
                color: #000;
                text-decoration: none;
                padding: $globalPadding/2 $globalPadding*2 $globalPadding/2 $globalPadding;
                transition: background-color .1s ease-in-out;
                position: relative;
                display: table;
                width: 100%;
                height: 60px;
                outline: 0 !important;
                
                &:hover {
                    text-decoration: hover;
                    background-color: $lightestgray;
                }

                > span {
                    position: relative;
                    display: table-cell;
                    vertical-align: middle;

                    &.icon i {
                        position: absolute;
                        right: -$globalPadding*2;
                        padding-top: 12px; /* just works for centering */
                        margin-top: -$globalPadding;
                        height: $globalPadding*2; //40 for reasonable hit area
                        width: $globalPadding*2; //40 for reasonable hit area
                        text-align: center;
                    }
                }


            }
            li {
                margin: 0;
                border-bottom: 0.5px solid $lightgray;
                &:last-child {
                    border-bottom: none;
                }
                &.active > a {
                    @extend .font-600;
                }

                &.has-children > a span.icon:hover {
                    color: $blue;
                }
            }
            ul {
                background-color: $almostwhite;
                border: none;
                li {
                    border-bottom: none;
                }
                a {
                    color: $text-black;
                    outline: 0;
                    &:hover {
                        background-color: $lightestgray;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $screen-sm-min) {
	.sidebar {
	    .sub-nav {
	    	margin-top: 0;
		}
	}
}