.categories-container {
  overflow: auto;
  padding: 10px 0;

  .categories-content-wrapper {
    width: 1170px;
    margin: 0 auto;
    height: auto;
    overflow: auto;
    margin-right: auto;
    margin-left: auto;
    transition: all 0.2s;

    .category-sidebar {
      transition: all 0.4s;
      margin-top: 8px;

      ul {
        i {
          @include font-size(29px);
          position: absolute;
          right: 2px;
          top: 7px;
          color: $blue;
          display: none;
          cursor: pointer;
          z-index: 5;
          transition: all 0.2s;
          line-height: 26px;
          height: 26px;

          &:hover {
            color: $darkblue;
          }
        }

        li {
          a {
            padding: 4px 0;
            display: inline-block;
            text-transform: none;
            color: $darkergray;
            @extend .font-600;
            transition: all 0.2s;
            padding-left: 0 !important;
            @include font-size(20px);
            cursor: pointer;

            &:hover {
              @extend %category-active;
            }
            &:focus {
              @extend %category-active;
            }
            &:active {
              @extend %category-active;
            }
          }

          &.active * {
            @extend %category-active;
          }
        }
      }
    }

    .category-items {
      @include font-size(12px);
      @extend .font-600;

      .category-item {
        overflow: auto;
        padding: 20px 0;
        border-bottom: 1px solid $lightestgray;

        .category-item-info {
          padding: 0px 30px;
          padding-right: 49px;

          span {
            display: block;
            padding: 4px 0px;
          }

          .category-item-title {
            padding-top: 0px;
            color: $blue;
            @extend %category-item-url;

            a {
              color: $blue;
              @extend %category-item-url;

              &:hover {
                color: $darkblue;
              }
            }
          }

          .category-item-title {
            @extend %category-item-title;
            a {
              @extend %category-item-title;

              &:hover {
                color: $bluelighter;
              }
            }
          }

          .title-mobile-show {
            display: none;
          }

          .category-item-intro {
            font-weight: 600;
            color: #333;
            @include font-size(12px);

            a {
              color: $text-dark;
              text-decoration: none;
            }

            * {
              @extend .font-500;
            }
          }

          .category-item-date {
            color: #777;
            text-decoration: none;
            transition: all .2s;
            font-weight: 400;
          }
        }
        .category-item-img {
          padding-right: 0;

          img {
            max-width: 165px;
          }

          a {
            @extend %category-item-title;

            &:hover {
              color: $bluelighter;
            }
          }

          span {
            @extend %category-item-title;
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .categories-container {
    .categories-content-wrapper {
      width: 970px;

      .category-item-info {
        padding: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .categories-container {
    overflow: auto;
    padding: 10px 0;

    .categories-content-wrapper {
      padding-left: 15px;
      padding-right: 15px;
      width: 750px;

      .category-sidebar {
        max-height: 46px;
        overflow: hidden;
        padding: 0;

        ul {
          i {
            display: inline-block;
          }
          li {
            opacity: 0;
            margin-left: -6px;
            a {
              @include font-size(22px);
            }
          }
          li:nth-child(2) {
            opacity: 1 !important;
            margin-left: 0px;
            a {
              @include font-size(22px);
            }
          }
        }
      }

      .category-items {
        padding: 0;

        .category-item {
          .category-item-img {
            padding: 10px 0 0 0 !important;

            img {
              float: none !important;
            }
          }

          .no-img {
            display: none;
          }

          .category-item-info {
            .category-item-title {
              display: inline-block !important;
              margin-right: 18px;
            }

            .category-item-date {
              display: inline-block !important;
              position: relative;

              &:before {
                position: absolute;
                content: "";
                left: -12px;
                top: 12px;
                background-color: #ACACAC;
                height: 2px;
                width: 4px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .categories-content-wrapper {
    width: auto !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}