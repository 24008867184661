.package-detail {
	.package-heading {
		position: relative; 
		margin-top: -66px;
		@extend .font-OpenSans;
	
		.package-visual {
			float: right;
			background: white; 
			border: 1px solid $lightgray; 
			text-align: center; 
			width: 100%; 
			margin-bottom: 20px;
			height: 0;
			padding-bottom: 100%;
			display: table-cell; 
			vertical-align: middle;
			position: relative;
			z-index: 5;
			
			img {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
			}
		}
	
		.package-title-name {
			color: $text-white; 
			@include font-size(24px);  
			margin: 24px 0 5px;
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		}
	
		.tabs {
			margin-top: 72px;
		}
	}
	
	.tab-content {
		margin-top: 20px;
	}
}

.package-data {
	position: relative;
	z-index: 5;
	
	.package-title-name {
		color: $text-white;
		@include font-size(24px);
		margin: 0 0 5px 0;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		float: left;
		clear: both;
		width: 100%;
	}
}

@media screen and (max-width: $screen-xs-max) {
	.package-detail {
		margin-top: 10px;
		
		.package-heading {
			
			.package-visual {
				margin-bottom: 0;
				padding: 0;
				display: table !important;
			
				img {
					position: static;
				}
			}
		
			.xs-push {
				padding-left: 0 !important;
				
				a.product-download-datasheet {
					float: left !important;
				}
			}
			
			.xs-pull, .tabs {
				margin-top: 20px;
			}
		}
	}	
	
	.package-data {
		text-align: left;
		
		.package-title-name {
			color: $text-black !important; 
			text-shadow: none !important;
			margin: 0px;
		}
	
	}
}

@media screen and (max-width: $screen-xs-min) {
	.package-detail {
		.package-data {
			text-align: center;
		}
	}
}