$transitionspeed: 	200ms;

.parametric-search {
	border-top: none !important;

	.ps-controls {
		#toggle-parameters {
			float: left;
		}
		.button-special {
			float: left;
			margin-left: 5px;

			a.btn {
				text-decoration: none !important;
			}
		}
	}

	.overlay {
		height: 100%;
		width: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 9;
		background: rgba(255,255,255,0.7);
		display: none;
		cursor: pointer;
	
		&.active {
			display: block;
		}
		
		.close-pop-in {
			position: absolute;
			right: 50%;
			margin-right: -200px;
			top: 40px;
			z-index: 8;
			font-size: 24px;
			color: $blue;
		}
	}
	
	.container {
		margin-top: 50px;
	}
	
	.wrapper {
		position: relative; 
		width: 100%; 
		height: 100%; 
		float: left;
	
		.preloader {
			position: absolute; 
			height: 300px; 
			background: white; 
			width: 100%; 
			top: 0px;
	
			img {
				position: absolute; 
				top: 50%; 
				left: 50%;
			}
		}
	}
	
	#search-type, #search-text {
		width: 180px; 
		margin-right: 5px;
	}
	
	
	@mixin pop-in {
		min-width: 220px;
		min-height: 40px;
		background: #ddd;
		opacity: 1;
		z-index: 999;
		-webkit-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.5);
		box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.5);
	
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
	
			li {
				a {
					display: block;
					padding: 8px;
					margin: 1px;
					background: white;
					text-decoration: none !important;
	
					&:hover, &:active, &:focus {
						text-decoration: none !important;
						background: $blue;
						color: white;
					}
				}	
			}
		}
	}
	
	.parameters {
		@include pop-in;
		
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 60px;
		left: 50%;
		height: auto;
		width: 400px;
		margin-left: -200px;
		overflow-y: auto;
		z-index: 1;
		transition: top $transitionspeed, opacity $transitionspeed, visibility $transitionspeed;
		
		&.active {
			opacity: 1;
			visibility: visible;
			top: 70px;
			z-index: 15;
			overflow: visible;
			
			.close-parameters {
				position: absolute;
				top: -32px;
				right: 0px;
				background: white;
				padding: 6px 12px;
				border: 1px solid #ddd;
				color: $blue;
				cursor: pointer;
				z-index: -1;
				-webkit-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.5);
				-moz-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.5);
				box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.5);
				
				&:hover {
					background: $blue;
					color: white;
				}
			}
		}
		
		.heading {
			width: 100%;
			background: white;
			font-weight: bold;
			font-size: 16px;
			padding: 15px;
		}
	
		ul {
			li {
				a {
					&.active {
						i {
							display: inline-block;
							opacity: 1;
						}
					}
	
					i {
						margin-right: 3px;
						opacity: 0;
						transition: opacity $transitionspeed;
					}
				}
			}
		}
	}
	
	
	
	.filter-column {
		@include pop-in;
		
		opacity: 0;
		visibility: hidden;
		position: fixed;
		top: 45%;
		left: 50%;
		height: 400px;
		width: 400px;
		margin-left: -200px;
		margin-top: -200px;
		overflow-y: auto;
		z-index: 0;
		transition: top $transitionspeed, opacity $transitionspeed, visibility $transitionspeed;
	
		&.active {
			opacity: 1;
			visibility: visible;
			top: 50%;
			z-index: 15;
		}
	
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
	
			li {
				list-style-type: none;
	
				div {
					display: block;
					padding: 8px;
					margin: 1px;
					background: white;
					text-decoration: none !important;
					font-weight: normal;
					color: $blue;
					cursor: pointer;
	
					i {
						color: $blue;
						opacity: 1;
					}
					
					input[type='checkbox'] {
						display: none;
					}
	
					&.inactive {
						i {
							opacity: 0;
						}
					}
	
					&:hover, &:active, &:focus {
						text-decoration: none !important;
						background: $blue;
						color: white;
	
						i {
							color: white;
						}
					}
				}
			}
		}
	}
	
	i.lnr {
		font-weight: 300 !important;
	}
	
	.btn, a {
		outline: none !important;
	
		.lnr {
			font-weight: 300;
		}
	}
	
	.overlay-popup {
		background: white;
		border: 1px solid $blue;
		display: none;
	
		ul {
			margin: 0;
			padding: 0;
	
			li {
				list-style-type: none;
				padding: 5px;
				border-bottom: 1px solid #ccc;
			}
		}
	}
	
	.btn-default {
		border-color: $blue;
		color: $blue;
		transition: background $transitionspeed;
	
		&:visited {
			border-color: $blue;
			color: $blue;
			background-color: white;
		}
	
		&:hover, &:focus {
			background: $blue !important;
			border: 1px solid $blue !important;
			color: white !important;
		}
	
		&.active, &:active {
			background-color: $blue !important;
			color: white !important;
			border-color: $blue;
		}
	}
	
	.form-control, .btn-default {
		&.pull-right {
			margin-left: 5px;
		}
	}
	
	a {
		color: $link-color;
	
		&:hover {
			color: $link-hover-color;
			text-decoration: $link-hover-decoration;
		}
	}
	
	#parametric-search__table {
		margin-top: 0;
		float: left;
		position: relative;

		td[data-column], th[data-column] {
						
			&.disabled {
				display: none;
			}
			
			&.nowrap {
				white-space: nowrap;
			}
		}
					
		thead {
			background: transparent;
						
			th {
				position: relative;
				padding-right: 8px !important;

				a {
					color: $text-black;
					text-decoration: none;
					position: relative;
					font-weight: bold;
					display: block;
					padding-right: 20px !important;
	
					.arrow-up, .arrow-down {
						width: 0; 
						height: 0; 
						border-left: 4px solid transparent;
						border-right: 4px solid transparent;
						position: absolute;
						border-radius: 0;
						margin-left: 4px;
						right: 0;
	
						&.inactive {
							opacity: 0.4;
						}
					}
	
					.arrow-up {
						border-bottom: 4px solid $text-black;
						bottom: 11px;
					}
	
					.arrow-down {
						border-top: 4px solid $text-black;
						bottom: 6px;
					}
	
					&.filter {
						font-size: 16px;
						font-weight: 300;
						margin-top: -20px;
						float: right;
						
						.lnr {
							font-weight: 300;
						}
	
						.filtered {
							margin-left: 3px;
							font-size: 12px;
							display: none;
	
							&:before {
								content: "(";
							}
							&:after {
								content: ")";
							}
						}
					}
				}
			}	
		}
	
		tbody {
			td {
				&.center {
					text-align: center;
				}
			}
		}
	}
	
	@media screen and (max-width: $screen-xs-max) {
		.ps-controls {
			#save-excel, #reset-filters {
				width: 100%;
				margin-bottom: 5px;
				float: left !important;
				margin-left: 0;
			}
			
			#save-excel {
				float: right !important;
				margin-left: 5px;
			}
			
			#search-type, #search-text, #toggle-parameters {
				width: 100%;
				margin-bottom: 5px;
				float: left !important;
			}
			
			.button-special {
				width: 100%;
				float: left;
				margin-left: 0;
			}
		}
		
		.parameters {
			width: 90%;
			margin: 0 auto 0 -40%;
			position: absolute;
			left: 45%;
			top: 0;
		}
		
		.table-responsive {
			margin-top: 20px;
			
			#parametric-search__table {
				margin-top: 0;
			}
		}
	}
}

