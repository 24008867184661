.social-toolbar-wrapper {
  margin-bottom: 20px;
  overflow: auto;
  > .col-md-6 {
    padding-left: 0;
  }

  .follow-us {
    padding-left: auto;
    @include font-size(13px);
    font-weight: 600;
    margin-top: 3px;
    width: 100px;
    display: inline-block;
  }

  .social-share-icons {
    float: right;

    .icon {
      margin: 0 5px;
      text-decoration: none;
      transition: all 0.2s;
      display: inline-block;


      img {
        max-height: 26px;
      }

      &:hover {
        filter: grayscale(50%);
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .social-toolbar-wrapper {
    width: 100%;

    > .col-md-6 {
      width: 100%;
    }

    .follow-us {
      padding-left: 0 !important;
      width: 100% !important;
    }

    .social-share-icons {
      width: 100%;
      padding-left: 0;
      margin-top: 8px;

      .icon {
        margin-left: 0 !important;
        margin-right: 5px !important;
      }
    }
  }
}