.image-header-wrapper {
  height: 470px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 3;
  margin: 0px;

  .container {
    position: relative;
    height: auto;
    height: 470px;
  }

  .image-header-nobg {
    @extend %image-header-background;
    background-color: $dark;
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .banner-gradient {
    @extend %banner-gradient-bg;
  }

  img {
    @extend %image-header-background;
    z-index: 2;
  }

  .image-header-headline {
    position: absolute;
    top: 200px;
    padding-right: 38px;
    z-index: 4;
    width: 100%;

    * {
      text-decoration: none;
      display: block;
      margin: 0;
      font-family: $brand-font-family;
      @extend .font-600;

      .headline-blue {
        color: $blue;
      }
    }
  }
}

.header-link{
    pointer-events: all !important;
    display: inline-block !important;

    .header-button{
      position: relative;
      z-index: 4;
      font-size: 14px !important;
      font-weight: 400 !important;
      margin-top: 15px !important;
    }
}

@media screen and (max-width: 750px) {
  .image-header-wrapper .image-header-nobg,
  .image-header-wrapper img {
    width: auto;
    height: 100%;
    transform: scale(1.2);
  }
}

.image-header-headline-noweight{
  span {
    font-weight: initial !important;
  }
}

.image-header-headline-noweight{
  span {
    font-weight: initial !important;
  }
}