.carousel-container {
  width: 100% !important;

  .carousel-wrapper {
    position: relative;
    z-index: 1;
    width: 1170px;
    margin: 0 auto;
    height: auto;
    overflow: auto;
    margin-right: auto;
    margin-left: auto;
    transition: all 0.2s;

    .carousel-content-wrapper {
      overflow: auto;
      height: 100%;
      position: relative;
    }

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .carousel-indicators {
      height: 40px;
      webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-bottom: 0;
      margin-top: 30px;
      padding: 0;

      position: relative;
      width: auto;
      margin-left: auto;
      left: auto;

      li {
        width: 3px;
        height: 20px;
        border-radius: 50px;
        background-color: $text-black;
        transition: all 0.2s;
        border: none;
        margin: 0 2px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

        &.active {
          height: 30px;
          background-color: $blue;
          margin-top: -10px;
        }
      }
    }

    .container {
      position: relative;
      z-index: 9;
    }

    .banner-gradient {
      @extend .gradient-diagonal;
      width: 100%;
      height: 100%;
      margin-top: -58px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    .carousel-inner {
      .item {
        background-size: cover;
        height: 315px;

        .carousel-content-details {
          display: table;
          height: 100%;

          .carousel-details-wrapper {
            display: table-cell;
            vertical-align: middle;
            overflow: auto;

            .carousel-caption {
              text-align: left;
              position: relative;
              display: block;
              width: 100%;
              left: auto;
              right: auto;
              bottom: auto;
              padding: 0px;
              color: inherit;
              text-align: initial;
              text-shadow: inherit;

              .caption-content {
                display: table-cell;
                vertical-align: middle;

                h5 {
                  @include font-size(21px);
                  border: none;
                  margin-bottom: 40px;
                  padding: 0;
                  line-height: 1.7rem;
                  font-weight: 600;
                }

                h4 {
                  font-weight: 500;
                  color: #868686;
                }

                p {
                  @include font-size(12px);
                  font-weight: 500;
                }

                a {
                  text-decoration: none;

                  span {
                    @include font-size(14px);
                    color: white;
                    margin-bottom: 10px;
                  }

                  &.btn {
                    margin-top: 12px;
                    text-align: left;
                    white-space: initial;

                    span {
                      float: left;
                      margin-bottom: 0;

                      .lnr {
                        font-size: 20px !important;
                        float: right;
                        margin: -4px 0 0 24px;
                        font-weight: 300;
                      }
                    }
                  }

                  &:hover {
                    h3, span, .btn {
                      color: #eee;
                    }
                  }
                }
              }
            }
          }
        }

        .carousel-options {
          margin-top: 30px;
          a {
            color: $blue;
            text-decoration: none;
            transition: all 0.2s;
            font-weight: 600;
            margin-right: 40px;
            @include font-size(15px);

            &:hover, &:active {
              color: $bluelighter;
            }
          }
        }
      }
      .carousel-item-img {
        height: 100%;

        img {
          max-width: 70%;
          position: absolute;
          top: 0;
          left: 15px;
          margin: auto;
          bottom: 0;

          &.mirror {
            margin: 0 !important;
            right: 0px !important;
            left: auto !important;
          }
        }
        .video-carousel-wrapper {
          padding-top: 30px;

          &.mirror {
            video, iframe {
              margin: 0 !important;
              right: 0px !important;
              left: auto !important;
            }
          }

          video {
            max-width: 70%;
            max-height: 100%;
            height: 75%;
            position: absolute;
            top: 30px;
            left: 15px;
            margin: auto;
            bottom: 0;
          }

          iframe {
            max-width: 70%;
            max-height: 80%;
            position: absolute;
            top: 30px;
            left: 15px;
            margin: auto;
            bottom: 0;
          }
        }
      }
    }
  }
}

/* Media queries */

@media only screen and (max-width: 1200px) {
  .carousel-container {
    .carousel-wrapper {
      width: 970px;
      display: block;

      .carousel-item-img {
        padding: 0;
        text-align: left !important;

        img {
          left: 0 !important;
        }
      }

      .carousel-content-details {
        padding: 0;

        h3 {
          padding: 6px 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .carousel-container {
    overflow: auto;
    padding: 10px 0;
    .carousel-wrapper {
      position: relative;
      z-index: 1;
      padding-left: 15px;
      padding-right: 15px;
      width: 750px;

      .carousel-content-wrapper {
        display: flex;
        flex-direction: column-reverse;

        .carousel-item-img {
          text-align: center;
          img {
            max-width: 260px !important;
            position: absolute !important;
          }
        }

        .carousel-content-details {
          .carousel-details-wrapper {
            position: relative !important;
            top: auto !important;
            transform: translate(0, 0) !important;

            .carousel-options {
              margin-bottom: 20px;
              display: table;
              width: 100%;

              a {
                @include font-size(14px);
                display: table-cell;
                width: 49%;
                margin: 0;
                padding: 0px 10px;
                padding-left: 0;
              }
            }

            .carousel-caption {
              .caption-content {
                h3 {
                  margin-bottom: 15px !important;
                }
              }
            }
          }
        }
      }

      .carousel-inner {
        margin-bottom: 25px;
        .item {
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .carousel-wrapper {
    width: auto !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .carousel-item-img {
    height: 200px !important;
  }
}

.hide-indicator {
  display: none;
}

.carousel-text {
  p {
    font-size: 14px !important;
  }
}

@media screen and (min-width: $screen-sm-min) {
  .carousel-caption {
    left: auto;
    right: auto;
    padding-bottom: auto;
  }
}