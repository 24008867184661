.application {
	.application-intro {
		margin-top: -44px;
		margin-bottom: 44px;
		
		.application-title {
			color: $text-white; 
			@include font-size(24px);  
			margin: 0 0 50px 0;
		}
	}
}