.tabs {
	list-style-type: none;
	width: 100%;
	margin: 0 0 20px 0;
	padding: 0;
	clear: both;
	display: table;
	border-bottom: 0 !important;
	>li.active>a {
		border-bottom-color: $blue;
		&:hover,
		&:active,
		&:focus {
			border-bottom-color: $blue;
		}
	}

	li {
		display: table-cell;
		text-align: center;
		background: $text-white;
		float: none;
		
		&:first-child a {
			border-left: 0 !important;
		}
						
		a {
			padding: 20px;
			display: block;
			border-bottom: 1px solid $lightgray;
			border-left: 1px solid $lightgray;
			border-radius: 0;
			border-top: 0 !important;
			border-right: 0 !important;
			background: $text-white;
			position: relative;
			color: $text-black;
			text-decoration: none;
			@extend .font-OpenSans;
			margin-right: 0;
			outline: 0 !important;
			transition: all .2s ease-in-out;

			&:hover {
				border-bottom-color: $blue;
			}
							
			&:before {
			  content: " ";
			  display: block;
			  height: 18px;
			  width: 18px;
			  border-left: 1px solid $lightgray;
			  border-top: 1px solid $lightgray;
			  position: absolute;
			  border-radius: 25%;
			  transform: rotate(45deg);
			  left: 50%;
			  margin-left: -9px;
			  bottom: -22px;
			  background: white;
			  transition: all .2s ease-in-out;
			}
			
			&:after { 
			  content: " ";
			  display: block;
			  background: white;
			  height: 16px;
			  width: 30px;
			  position: absolute;
			  left: 50%;
			  margin-left: -15px;
			  bottom: -17px;
			}
		}
		
		&.active, &:hover {
			a {
				border-bottom-color: $blue;
				color: $blue !important;
				background: none;

				&:before {
					bottom: -12px;
					border-color: $blue;
				}
			}
		}
	}
}
@media screen and (max-width: $screen-xs-max) {
    .tabs {
        li {
            display: block;
            width: 100%;
            margin-bottom: 0;
            a {
                border-left: none !important;
            }
        }
    }
    .xs-static {
        position: static;
        width: 100%;
    }
    .xs-absolute {
        position: absolute;
    }
    .xs-push {
        /*padding-left: 33.3333333333%;*/
        top: 0;
        position: absolute;
    }
    .xs-pull {
        margin-top: -20px;
    }
}

.searchResult {
	.content {
		.xs-pull {
			margin-top: -32px;
		}
	}
}

