.top-bar {
	background-color: $darkblue;
	background-color: rgba($darkblue, 0.95);
	height: 60px;
	width: 100%;
	top: 50px;
	left: 0;
	z-index: 2;
	overflow: hidden;

	ol {
		background: none;
		border-radius: none;
		margin: 0;
		padding: 20px 0;
	
		li {
			a {
				color: $text-white;
			}
		
			&.active {
				@extend .font-600;
			}
		}	
	}
}

// Add this fix because LibSass double escapes the \00a0 in bootstrap >.<
.breadcrumb {
   > li {

    + li:before {
      content: $breadcrumb-separator + "\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      font-family: $iconfontname;
      font-size: 10px;
      font-weight: 600;
      color: $text-white;
    }
  }
}
.breadcrumb-navigation {
	height: 60px;
	overflow: hidden;
}
.breadcrumb {
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  overflow: auto;
  padding-bottom: 20px;
  @include no-select-no-callout();
  .js.opacity & {
    opacity: 0;
    &.show {
      opacity: 1;
    }
  }
}


.share {
	&.hidden-xs {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 5;
		margin-top: 15px;
	}
	
	&.visible-xs {
		margin: 0 auto;
		padding: 0;
		border-top: 1px solid #57a1d5;
		
		li {
			text-align: center;
			margin: 0 !important;
			width: 25%;
		}
	}
	
	list-style-type: none;
	
	li {
		float: left;
		list-style-type: none;
		margin: 0 2px;
		
		a {
			font-size: 20px;
			transition: opacity 300ms;
			
			&:hover {
				opacity: 0.6;
			}
		}
	}
}