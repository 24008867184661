.termsOfService {

  .page-title{
    text-align:left;
    font-size: 24px;
  }

  .tosContainer {
    text-align: center;
  }

  .tosBox {
    margin: auto auto;
    display: block;
    text-align: left;
    overflow: auto;
    max-height: 100%;
    height: 250px;
    width: 100%;
    border: 1px solid #ccc;
    padding: 15px;
  }

  .tosBtns {
    margin: 15px;
  }


  .hidden {
    display: none;
  }

  .noScroll {
    overflow: hidden !important;
    height: 100%;
    margin: 0;
    opacity: 0.5;
  }

  .popup {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: rgb(0,0,0);
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    overflow: hidden;
    z-index: 1;
  }

  .info-bubble {
    background: white;
    overflow: hidden;
    padding: 20px;
    border: 1px solid #ccc;
    width: 25%;
    z-index: 999;
    margin: 20% auto auto;
  }

  @media only screen and (max-width: 767px) {
    .info-bubble {
      width: 80%;
      margin: 50% auto auto;
    }
  }
}
