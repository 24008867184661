.productOverview {
	.product-overview-heading {
		position: relative; 
		margin-top: -45px;
		z-index: 5;
		@extend .font-OpenSans;
		
		.product-overview-title {
			color: $text-white; 
			@include font-size(24px);  
			background: $blue;
			margin: -17px 0 0 0;
	    	padding: 20px;
		}
		
		.product-overview-title-name {
			color: $text-white; 
			@include font-size(24px);  
			margin: 0 0 22px 0;
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		}
	}
		
	.product-categories {
		padding: $componentPadding;
	
		.category-title {
			color: $text-black;
			font-weight: 600;
			float: left;
			margin-bottom: 5px;
						
			.lnr {
				color: $blue;
			    float: right;
			    font-size: 20px;
			    margin: 0 0 0 4px;
			}
		}
	
		.products-list {
			clear: both;
		    float: left;
		    list-style-type: disc;
		    padding: 0 0 0 15px;
		
			li {
				color: $blue;
    			padding: 0;
    			
    			a {
    				color: $text-black;
    				 
    				.total-products {
    					color: $gray;
    				} 
    				    				
    				&:hover {
    					color: $blue;
    					
    					.total-products {
    						color: $blue;
    					}
    				}
    			}
			}
		}
	}
	
	@media screen and (max-width: $screen-sm-max) {
		.product-overview-heading {
			height: 0;
			margin-top: 0;
			
			.sub-nav {
				display: none;
			}
		}
		
		.product-categories {
			.col-md-3 {
				margin-bottom: 12px;
			}
		}
	}
}
