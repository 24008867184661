.subfooter-content-wrapper {
  padding: 10px 0px;

  .site-map-wrapper {
    padding-left: 0;
    margin-top: 20px;

    span {
      display: block;
      margin-bottom: 24px;

      a {
        @extend %sitemap-link;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}


@media only screen and (max-width: 992px) {
  .subfooter-content-wrapper {
    padding: 0;

    .site-map-wrapper {
      overflow: auto;
    }
  }
}