.infusion-field{
  display: block;
  margin-bottom: 15px;
}

.infusion-field input {
  width: 100%;
}

.infusion-field input[type='checkbox'] {
  width: 2%;
}

.infusion-field input[type='radio'] {
  width: 2%;
}

.infusion-field select {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  padding: 6px 12px;
}

.infusion-field select:checked:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.infusion-field select > option:hover {
  box-shadow: 0 0 10px 100px #f5f5f5 inset;
  -moz-box-shadow: 0 0 10px 100px #f5f5f5 inset;
  -webkit-box-shadow: 0 0 10px 100px #f5f5f5 inset;
}

.infusion-submit button{
  color: #fff;
  background-color: #00a8e1;
  border-color: #00a8e1 ;
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
  @include user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: #fff;
    background-color: #00a8e1;
    border-color: #00a8e1;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    //@include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
    color: #fff;
    background-color: #00a8e1;
    border-color: #00a8e1;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    @include box-shadow(none);
  }
}

.infusion-submit button:hover {
  color: #337ab7 !important;
  background-color: #fefefe !important;
  border-color: #e2e2e2 !important;
}
